import chunk from 'lodash/fp/chunk';
import flow from 'lodash/fp/flow';
import slice from 'lodash/fp/slice';
import flatten from 'lodash/fp/flatten';
import SortDirection from '@rio-cloud/rio-uikit/SortDirection';
import { sortByProperty } from '@rio-cloud/rio-uikit/SortUtils';

export const DATA_ATTRIBUTE = 'data-key';

export const getSortDir = (sortBy, previousSortBy, sortDir) => {
    if (sortBy === previousSortBy) {
        return sortDir === SortDirection.ASCENDING ? SortDirection.DESCENDING : SortDirection.ASCENDING;
    }
    return SortDirection.ASCENDING;
};

export const sortItems = (items, sortBy, sortDir) => {
    return sortBy ? sortByProperty(items, sortBy, sortDir) : items;
};

export const isMatchingValue = (value = '', match = '') => {
    return value.toString().toLowerCase().includes(match.toLowerCase());
};

export const chunkContent = (content, chunks, chunkSize) => {
    const chunkedResult = chunk(chunkSize, content);
    const chunkedContent = flow(slice(0, chunks), flatten)(chunkedResult);

    const hasMoreChunks = chunkedResult.length > chunks;

    return {
        chunkedContent,
        hasMoreChunks,
    };
};

export const getTranslatedLabels = (labels = {}, intl = {}) => {
    const translatedLabels = { ...labels };
    Object.keys(labels).map(key => {
        translatedLabels[key] = intl.formatMessage({ id: labels[key] }) || '';
    });
    return translatedLabels;
};
