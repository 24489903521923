import isEmpty from 'lodash/fp/isEmpty';

import { UNGROUPED_ITEMS_GROUP_ID } from '../fetchGroups';
import type { ApiDriver, ApiDriverList } from '../apiTypes';
import type { Driver } from '../types';

const buildName = ({ firstname, lastname }: ApiDriver): string => {
    let name = firstname || '';
    name += name && lastname ? ` ${lastname}` : lastname;
    return name;
};

export const mapDriversList = (driverList: ApiDriverList): Driver[] =>
    driverList.items.map(dto => {
        const groups = dto.group_ids;
        return {
            driverId: dto.id,
            driverName: buildName(dto),
            firstName: dto.firstname,
            lastName: dto.lastname,
            groupIds: groups && !isEmpty(groups) ? groups : [UNGROUPED_ITEMS_GROUP_ID],
        };
    });
