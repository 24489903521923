import * as Sentry from '@sentry/react';
// import { BrowserTracing } from '@sentry/browser'; // Must import after @sentry/react
import { config } from '../../config';

const UNDEFINED_TOKEN = '<YOUR SENTRY DSN>';

if (import.meta.env.PROD) {
    const release = config.serviceVersion;
    const environment = config.serviceEnvironment;
    const dsn = config.sentryToken;

    const hasToken = !dsn?.startsWith(UNDEFINED_TOKEN);

    if (hasToken) {
        Sentry.init({
            // integrations: [
            //     new BrowserTracing({
            //         tracePropagationTargets: ['livemonitor.rio.cloud', /^\//],
            //     }),
            //     new Sentry.Replay(),
            // ],
            // tracesSampleRate: 0.1,

            // This sets the sample rate to be 10%. You may want this to be 100% while
            // in development and sample at a lower rate in production
            //replaysSessionSampleRate: 0.1,

            // If the entire session is not sampled, use the below sample rate to sample
            // sessions when an error occurs.
            // replaysOnErrorSampleRate: 0.1,

            dsn,
            environment,
            // release,
        });
    }
}

// biome-ignore lint/suspicious/noExplicitAny: We don't know the type
export const reportErrorToSentry = (...args: [any, any?]) => {
    Sentry.captureException(...args);
};
