import { handleActions } from 'redux-actions';
import SortDirection from '@rio-cloud/rio-uikit/SortDirection';
import TableViewToggles from '@rio-cloud/rio-uikit/TableViewToggles';

import { tableConfig } from './tableConfig';
import { tableActions } from './tableActions';
import { summaryActions } from '../summary/summaryActions';
import { fastCloneDeep } from '../../utils/utils';

export const defaultTableState = {
    search: '',
    sortBy: tableConfig.defaultSortKey,
    sortDir: SortDirection.ASCENDING,
    chunks: 1,
    columns: {
        columnOrder: tableConfig.defaultColumnOrder,
        hiddenColumns: tableConfig.defaultHiddenColumns,
        columnsDetails: tableConfig.defaultColumnsDetails,
    },
    activityFilter: [],
    assetFilter: [],
    notificationFilter: [],
    showTableSettings: false,
    viewType: TableViewToggles.VIEW_TYPE_TABLE,
};

export const tableReducer = handleActions(
    {
        [tableActions.tableSearchChanged]: (state, action) => ({ ...state, search: action.payload }),
        [tableActions.tableColumnsChanged]: (state, action) => ({ ...state, columns: fastCloneDeep(action.payload) }),
        [tableActions.tableSettingsDialogToggled]: state => ({ ...state, showTableSettings: !state.showTableSettings }),
        [tableActions.tableViewTypeChanged]: (state, action) => ({ ...state, viewType: action.payload }),
        [tableActions.chunkCounterChanged]: (state, action) => ({ ...state, chunks: action.payload }),
        [tableActions.sortingPropsChanged]: (state, action) => ({
            ...state,
            sortBy: action.payload.sortBy,
            sortDir: action.payload.sortDir,
        }),
        [summaryActions.activityFilterChanged]: (state, action) => ({
            ...state,
            activityFilter: action.payload || defaultTableState.activityFilter,
        }),
        [summaryActions.assetTypeFilterChanged]: (state, action) => ({
            ...state,
            assetFilter: action.payload || defaultTableState.assetFilter,
        }),
        [summaryActions.notificationFilterChanged]: (state, action) => ({
            ...state,
            notificationFilter: action.payload || defaultTableState.notificationFilter,
        }),
    },
    defaultTableState
);
