import useEffectOnce from '@rio-cloud/rio-uikit/useEffectOnce';
import { type IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { getData, isFetchGroupsFailed, isFetchInitialDataFailed } from '../features/app/selectors';
import { fetchInitialData } from '../services/fetchInitialData';

export const useInitialData = () => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const loadInitialData = (intl: IntlShape) => dispatch(fetchInitialData(intl));

    useEffectOnce(() => loadInitialData(intl));

    const hasFetchInitialDataFailed = useSelector(isFetchInitialDataFailed);
    const hasFetchGroupsFailed = useSelector(isFetchGroupsFailed);
    const data = useSelector(getData);

    return {
        data,
        hasFetchInitialDataFailed,
        hasFetchGroupsFailed,
    };
};
