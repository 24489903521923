import { combineReducers } from 'redux';

import configReducer from '../configuration/setup/configSlice';
import langReducer from '../configuration/lang/langSlice';
import loginReducer from '../configuration/login/loginSlice';
import tokenReducer from '../configuration/tokenHandling/tokenSlice';

import { appReducer } from '../features/app/appReducers/appReducer';
import { contactFormApi } from './contactFormApi';

export const rootReducer = combineReducers({
    config: configReducer,
    lang: langReducer,
    app: appReducer,
    login: loginReducer,
    tokenHandling: tokenReducer,
    [contactFormApi.reducerPath]: contactFormApi.reducer,
});
