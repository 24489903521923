import cond from 'lodash/fp/cond';

import { ErrorMessage } from '../components/ErrorMessage';
import { MainPages } from '../pages/MainPages';

export const MainContent = props => {
    const { data, hasFetchInitialDataFailed, hasFetchGroupsFailed } = props;

    const hasErrors = () => (hasFetchInitialDataFailed && !data) || hasFetchGroupsFailed;
    const hasData = () => !hasErrors();

    const content = cond([
        [hasData, MainPages],
        [hasErrors, ErrorMessage],
    ])(props);

    return content;
};
