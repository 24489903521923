import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import Spinner from '@rio-cloud/rio-uikit/Spinner';

import { useUserProfile, type UserProfile } from '../../configuration/login/loginSlice';

export type FeatureBaseAction = 'embed';

type FeatureBaseWrapperProps = {
    rioAccessTokenResolver: () => Promise<string>;
    action: FeatureBaseAction;
    initialPage?: string;
    hideMenu?: boolean;
    hideLogo?: boolean;
    filters?: string;
    metadata?: Record<string, string>;
};

const featureVotingUrl = 'https://api.featurevoting.rio.cloud';

const fetchJwt = async (accessToken: string, userProfile: UserProfile | null): Promise<string | null> => {
    if (import.meta.env.MODE !== 'production') {
        return null;
    }

    const defaultName = 'A';
    const firstName = userProfile?.givenName ?? defaultName;
    const lastName = userProfile?.familyName ?? defaultName;
    const response = await fetch(`${featureVotingUrl}/token`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ user: { first_name: firstName, last_name: lastName } }),
    });

    if (!response.ok) {
        throw new Error(`Failed to fetch jwt token from ${featureVotingUrl}/token`);
    }

    return (await response.json()).token;
};

const FEATURE_BASE_LOCALES = [
    'bn',
    'bs',
    'pt-BR',
    'bg',
    'ca',
    'hr',
    'cs',
    'da',
    'nl',
    'en',
    'et',
    'fi',
    'fr',
    'de',
    'el',
    'hi',
    'hu',
    'id',
    'it',
    'ja',
    'ko',
    'lv',
    'lt',
    'ms',
    'mn',
    'nb',
    'pl',
    'pt',
    'ro',
    'ru',
    'sr',
    'zh-CN',
    'sl',
    'es',
    'sw',
    'sv',
    'th',
    'zh-TW',
    'tr',
    'uk',
    'vi',
];

const mapLocaleToFeatureBaseLocale = (locale: string): string => {
    if (FEATURE_BASE_LOCALES.includes(locale)) {
        return locale;
    }
    if (FEATURE_BASE_LOCALES.includes(locale.split('-')[0])) {
        return locale.split('-')[0];
    }
    return 'en';
};

export const FeatureBaseWrapper = (props: FeatureBaseWrapperProps) => {
    const { action, filters, hideMenu, hideLogo, metadata, initialPage, rioAccessTokenResolver } = props;

    const intl = useIntl();

    const userProfile = useUserProfile();

    const wrapperRef = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
        const setup = async () => {
            const rioAccessToken = await rioAccessTokenResolver();
            const theme = document.cookie.includes('uikit-color-scheme=dark') ? 'dark' : 'light';
            const featureBaseJwt = await fetchJwt(rioAccessToken, userProfile);
            // biome-ignore lint/style/noNonNullAssertion: We are sure to have a ref here
            wrapperRef.current!.innerHTML = '';
            window.Featurebase(action, {
                organization: 'rio',
                basePath: null,
                theme,
                initialPage,
                hideMenu: hideMenu ?? false,
                hideLogo: hideLogo ?? false,
                filters,
                jwtToken: featureBaseJwt,
                metadata,
                locale: mapLocaleToFeatureBaseLocale(intl.locale),
            });
        };
        setup().then();
    }, [action, filters, hideMenu, hideLogo, metadata, initialPage, intl, rioAccessTokenResolver, userProfile]);

    return (
        <div data-featurebase-embed='' ref={wrapperRef}>
            <div className='display-grid place-items-center min-height-400'>
                <Spinner isDoubleSized={true} />
            </div>
        </div>
    );
};
