import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ExpanderPanel from '@rio-cloud/rio-uikit/ExpanderPanel';
import IframeResizer from 'iframe-resizer-react';

import { TimedBottomSheet } from '../../components/TimedBottomSheet';
import { featureToggles } from '../../configuration/setup/featureToggles';
import { useUserAccount } from '../../configuration/login/loginSlice';
import { DEMO_FLEET_ACCOUNT_ID } from '../../data/dataDefinition';
import { useLocale } from '../../configuration/lang/langSlice';
import { gaPush, trackingAttributes, TRACKING_CATEGORIES } from '../../configuration/setup/googleTagManager';

/*
 * Demo fleet BottomSheet used for a specific demo account id.
 *
 * Note:
 * Don't forget to cleanup the localStorage for the user when removing a feature
 * by setting "cleanupLocalStorage" to "true" and deploy it like this.
 * The cleanup will remove the localStorage flag on mount and will not show the bottom sheet.
 */

// const allowedDemoAccounts = [DEMO_FLEET_ACCOUNT_ID, 'mockaccount'];
const allowedDemoAccounts = [DEMO_FLEET_ACCOUNT_ID];

export const DemoFleetBottomSheet = () => {
    const [isOpen, setIsOpen] = useState(true);

    const locale = useLocale();
    const userAccount = useUserAccount();

    // Only show this demo bottom sheet for the specific fixed demo fleet account id or in dev
    if (!featureToggles.demo || !allowedDemoAccounts.includes(userAccount)) {
        return null;
    }

    const handleToggleExpander = (open: boolean) => {
        setIsOpen(open);
        gaPush({
            category: TRACKING_CATEGORIES.PROMOTION,
            label: 'DemoBottomSheet expander',
            action: `DemoBottomSheet ${open ? 'expanded' : 'collapsed'}`,
        });
    };

    return (
        <TimedBottomSheet
            featureName='demoFleetBottomSheet'
            showAfter={5_000}
            alwaysOn
            showCloseButton={false}
            bodyClassName='padding-5'
            className='width-500'
            cleanupLocalStorage={false}
            {...trackingAttributes(TRACKING_CATEGORIES.PROMOTION, 'Demo fleet bottom sheet')}
        >
            <ExpanderPanel
                open={isOpen}
                title={
                    <div className='text-medium text-size-16'>
                        <FormattedMessage id='intl-msg:livemonitor.demoFleet.title' />
                    </div>
                }
                bodyClassName='padding-top-5'
                iconClassName={isOpen ? 'rotate-0' : 'rotate-180'}
                onToggle={handleToggleExpander}
            >
                <div>
                    <IframeResizer
                        src={`https://cloud.news.rio.cloud/demo_upselling?locale=${locale}`}
                        checkOrigin={false}
                        style={{ width: '1px', minWidth: '100%', border: '0' }}
                    />
                </div>
            </ExpanderPanel>
        </TimedBottomSheet>
    );
};
