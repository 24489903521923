interface StaticFeedbackButtonProps {
    onClick: () => void;
}

export const StaticFeedbackButton = ({ onClick }: StaticFeedbackButtonProps) => {
    return (
        <div
            className='position-fixed padding-left-5 padding-y-5 cursor-pointer overflow-hidden'
            data-testid='static-feedback-button'
            style={{ left: '0px', bottom: '50px', zIndex: 991 }}
        >
            <div className='display-flex align-items-center' onClick={onClick}>
                <div className='width-40 aspect-ratio-1 display-grid place-items-center position-relative z-index-1'>
                    <span className='rioglyph rioglyph-comment text-size-h2 always-text-color-white' />
                </div>
            </div>
        </div>
    );
};
