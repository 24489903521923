import { useEffect, useRef } from 'react';
import type { Dispatch } from 'redux';
import isEmpty from 'lodash/fp/isEmpty';
import useEffectOnce from '@rio-cloud/rio-uikit/useEffectOnce';
import Notification from '@rio-cloud/rio-uikit/Notification';

import { useLocale } from '../../configuration/lang/langSlice';

import {
    widgetLoadedType,
    centerActiveAssetType,
    centerMapType,
    setZoomType,
    setBoundingboxType,
    editCustomerPoiType,
    editGeofenceType,
    refreshPoisType,
    refreshGeofencesType,
    remoteActions,
    chargingStationsSelectedType,
    forwardToWidgetsType,
    treeCategoryChangedType,
    sidebarTabSelectionChangedType,
    toggleLayerChargingStationsType,
    toggleLayerSimplePayPoisType,
    chatTotalUnreadMessageCountChangedType,
    simplePayPoisSelectedType,
    showNotificationType,
    selectAssetType,
} from './remoteActions';
import {
    MapContext,
    boundingBoxChanged,
    zoomChanged,
    mapViewChanged,
    chargingStationsToggled,
    simplePayPoisToggled,
} from '../map/mapSlice';
import { showActiveAsset } from '../map/mapThunks';
import {
    ROUTEPLANNING_WIDGET,
    HISTORY_WIDGET,
    POI_DETAILS_WIDGET,
    GEOFENCE_DETAILS_WIDGET,
    ACTIVITY_WIDGET,
    RANGE_WIDGET,
    SMART_ROUTEPLANNING_SETTINGS_WIDGET,
    CHARGING_STATION_SEARCH_WIDGET,
    CHARGING_STATION_DETAILS_WIDGET,
} from './widgetsConfig';
import { poiGeofenceActions } from '../dialogs/poiGeofenceDialog/poiGeofenceActions';
import { actions } from '../../services/actions';
import { useAppDispatch } from '../../configuration/setup/hooks';
import { sidebarActions } from '../sidebar/sidebarActions';
import {
    useActiveAssetId,
    useActiveChargingStationId,
    useActiveGeofenceId,
    useActivePoiId,
    useVisibleWidgetIds,
} from '../app/appHooks';
import {
    useActiveAdditionalLayers,
    useBoundingBoxReadOnly,
    useCenter,
    useChargingStationClusterIds,
    useCurrentMapContext,
    useZoom,
} from '../map/mapHooks';
import type { EventActiveAdditionalLayerChanged, EventShowNotification } from './remoteActionTypes';
import {
    TREE_CATEGORY_POIS,
    TREE_POI_CATEGORY_CHARGING_STATIONS,
    TREE_POI_CATEGORY_CUSTOM_POIS,
    TREE_POI_CATEGORY_GEOFENCES,
    TREE_POI_CATEGORY_BOOKABLE_POIS,
    TREE_POI_CATEGORY_WORKSHOP_POIS,
} from '../tree/treeSlice';

export const PREFIX = 'EVENT';

const framesFilter = (frame: HTMLIFrameElement) => !(frame.src.includes('oidc') || frame.src.includes('buybutton'));

// biome-ignore lint/suspicious/noExplicitAny: We don't know the type
export const sendMessage = (message: any) => {
    const frames = Array.from(document.getElementsByTagName('iframe')).filter(framesFilter);
    if (isEmpty(frames)) {
        return;
    }

    [...frames].forEach(target => {
        const contentWindow = target.contentWindow;
        if (contentWindow) {
            contentWindow.postMessage(message, '*');
        }
    });
};

const getContext = (origin: string) => {
    if (HISTORY_WIDGET.isWidgetUrl(origin)) {
        return MapContext.MAP_CONTEXT_HISTORY;
    }
    if (ROUTEPLANNING_WIDGET.isWidgetUrl(origin)) {
        return MapContext.MAP_CONTEXT_ROUTEPLANNING;
    }
    return MapContext.MAP_CONTEXT_DEFAULT;
};

const getWidgetId = (origin: string) => {
    if (ROUTEPLANNING_WIDGET.isWidgetUrl(origin)) {
        return ROUTEPLANNING_WIDGET.name;
    }
    if (SMART_ROUTEPLANNING_SETTINGS_WIDGET.isWidgetUrl(origin)) {
        return SMART_ROUTEPLANNING_SETTINGS_WIDGET.name;
    }
    if (RANGE_WIDGET.isWidgetUrl(origin)) {
        return RANGE_WIDGET.name;
    }
    if (CHARGING_STATION_SEARCH_WIDGET.isWidgetUrl(origin)) {
        return CHARGING_STATION_SEARCH_WIDGET.name;
    }
    if (CHARGING_STATION_DETAILS_WIDGET.isWidgetUrl(origin)) {
        return CHARGING_STATION_DETAILS_WIDGET.name;
    }
    if (HISTORY_WIDGET.isWidgetUrl(origin)) {
        return HISTORY_WIDGET.name;
    }
    if (POI_DETAILS_WIDGET.isWidgetUrl(origin)) {
        return POI_DETAILS_WIDGET.name;
    }
    if (GEOFENCE_DETAILS_WIDGET.isWidgetUrl(origin)) {
        return GEOFENCE_DETAILS_WIDGET.name;
    }
    if (ACTIVITY_WIDGET.isWidgetUrl(origin)) {
        return ACTIVITY_WIDGET.name;
    }
};

// biome-ignore lint/suspicious/noExplicitAny: We don't know the type
export const enrichPayloadWithContext = (event: Record<string, any>) => {
    const context = event.data.payload.context ?? getContext(event.origin);
    return { ...event.data, payload: { ...event.data.payload, context } };
};

const showRemoteNotification = (payload: EventShowNotification) => {
    if (payload.type === 'success') {
        return Notification.success(payload.message, payload.title);
    }
    if (payload.type === 'error') {
        return Notification.error(payload.message, payload.title);
    }
    if (payload.type === 'warning') {
        return Notification.warning(payload.message, payload.title);
    }
    return Notification.info(payload.message, payload.title);
};

// biome-ignore lint/suspicious/noExplicitAny: We don't know the type
export const setupWidget = (event: any, refProps: any) => {
    const props = refProps.current;
    const {
        visibleWidgetIds,
        currentMapContext,
        activeAssetId,
        activePoiId,
        activeGeofenceId,
        activeChargingStationId,
        clusterChargingStationIds,
        mapBoundingBox,
        mapCenter,
        mapZoom,
        activeAdditionalLayers,
    } = props;

    const widgetId = getWidgetId(event.origin);

    // In the rare case where there is no source, don't continue (LIVEMONITOR-WEB-1V0 in Sentry)
    if (!event.source) {
        return;
    }

    // reply to widget being loaded, e.g. send current app state, map position etc.
    event.source.postMessage(remoteActions.setWidgetId(widgetId), '*');
    event.source.postMessage(remoteActions.setVisibleWidgets(visibleWidgetIds), '*');
    event.source.postMessage(remoteActions.setMapContext(currentMapContext), '*');
    event.source.postMessage(remoteActions.selectAsset({ id: activeAssetId }), '*');
    event.source.postMessage(remoteActions.mapChanged({ zoom: mapZoom, bbox: mapBoundingBox, center: mapCenter }), '*');
    event.source.postMessage(remoteActions.activeAdditionalLayerChanged(activeAdditionalLayers), '*');

    // Specific replies for certain widgets
    switch (widgetId) {
        case POI_DETAILS_WIDGET.name: {
            event.source.postMessage(remoteActions.selectPois({ ids: activePoiId ? [activePoiId] : [] }), '*');
            break;
        }
        case GEOFENCE_DETAILS_WIDGET.name: {
            event.source.postMessage(
                remoteActions.selectGeofences({ ids: activeGeofenceId ? [activeGeofenceId] : [] }),
                '*'
            );
            break;
        }
        case CHARGING_STATION_SEARCH_WIDGET.name:
        case CHARGING_STATION_DETAILS_WIDGET.name: {
            // reply to widget about the current state of charging stations and cluster selection
            event.source.postMessage(
                remoteActions.chargingStationsSelected({
                    ids: activeChargingStationId ? [activeChargingStationId] : clusterChargingStationIds,
                }),
                '*'
            );
            break;
        }
        default:
            break;
    }
};

// biome-ignore lint/suspicious/noExplicitAny: We don't know the type
const dispatchCenterMap = (dispatch: Dispatch, payload: any) => {
    const { lat, lng, zoom } = payload;
    const center = { lat, lng };
    dispatch(mapViewChanged({ center, zoom }));
};

// biome-ignore lint/suspicious/noExplicitAny: We don't know the type
const dispatchEditCustomerPoi = (dispatch: Dispatch, payload: any) => {
    dispatch(poiGeofenceActions.setPoiId(payload.id));
    dispatch(poiGeofenceActions.poiDialogToggled(true));
};

// biome-ignore lint/suspicious/noExplicitAny: We don't know the type
const dispatchGeofence = (dispatch: Dispatch, payload: any) => {
    dispatch(poiGeofenceActions.setGeofenceId(payload.id));
    dispatch(poiGeofenceActions.geofenceDialogToggled(true));
};

const dispatchTreeCategoryChanged = (dispatch: Dispatch, payload: any) => {
    // Grouped tabs under "pois" = "customPois" | "workshopPois" | "geofences" | "charging" | "bookablePois"
    const groupedPois = [
        TREE_POI_CATEGORY_CUSTOM_POIS,
        TREE_POI_CATEGORY_WORKSHOP_POIS,
        TREE_POI_CATEGORY_GEOFENCES,
        TREE_POI_CATEGORY_CHARGING_STATIONS,
        TREE_POI_CATEGORY_BOOKABLE_POIS,
    ];
    if (groupedPois.includes(payload)) {
        dispatch(actions.treeCategoryChanged(TREE_CATEGORY_POIS));
        dispatch(actions.treePoiCategoryChanged(payload));
    } else {
        dispatch(actions.treeCategoryChanged(payload));
    }
};

// biome-ignore lint/suspicious/noExplicitAny: We don't know the type
export const receiveMessage = (event: any, refProps: any) => {
    const { dispatch } = refProps.current;

    const { type = '', payload } = event.data;

    // Listen to the cloud page lead form submit event
    // which helps to close a bottom sheet after the form was submitted
    if (type === 'LEAD_FORM_SUBMITTED') {
        console.log(event);
        return;
    }

    if (typeof type !== 'string' || !type.startsWith(PREFIX) || !dispatch) {
        return;
    }

    switch (type) {
        case widgetLoadedType:
            return setupWidget(event, refProps);
        case setBoundingboxType:
            return dispatch(boundingBoxChanged(payload.bbox));
        case centerMapType:
            return dispatchCenterMap(dispatch, payload);
        case centerActiveAssetType:
            return dispatch(showActiveAsset);
        case setZoomType:
            return dispatch(zoomChanged(payload.zoom));
        case editCustomerPoiType:
            return dispatchEditCustomerPoi(dispatch, payload);
        case editGeofenceType:
            return dispatchGeofence(dispatch, payload);
        case refreshPoisType:
            return dispatch(remoteActions.refreshPois());
        case refreshGeofencesType:
            return dispatch(remoteActions.refreshGeofences());
        case chargingStationsSelectedType: {
            dispatch(chargingStationsToggled(true));
            dispatch(actions.activeChargingStationChanged(payload.ids[0]));
            return;
        }
        case simplePayPoisSelectedType: {
            dispatch(simplePayPoisToggled(true));
            dispatch(actions.activeSimplePayPoiChanged(payload.ids[0]));
            return;
        }
        case treeCategoryChangedType:
            return dispatchTreeCategoryChanged(dispatch, payload);
        case sidebarTabSelectionChangedType:
            return dispatch(sidebarActions.sidebarTabSelectionChanged(payload));
        case forwardToWidgetsType:
            return sendMessage(remoteActions.forwardToWidgets(payload));
        case toggleLayerChargingStationsType:
            return dispatch(chargingStationsToggled(payload));
        case toggleLayerSimplePayPoisType:
            return dispatch(simplePayPoisToggled(payload));
        case chatTotalUnreadMessageCountChangedType:
            return dispatch(remoteActions.chatTotalUnreadMessageCountChanged(payload));
        case showNotificationType:
            return showRemoteNotification(payload);
        case selectAssetType:
            return dispatch(actions.activeAssetChanged(payload.id));
        default:
            return dispatch(enrichPayloadWithContext(event));
    }
};

export const MessageHandler = () => {
    const dispatch = useAppDispatch();

    const locale = useLocale();
    const activeAssetId = useActiveAssetId();
    const activePoiId = useActivePoiId();
    const activeGeofenceId = useActiveGeofenceId();
    const activeChargingStationId = useActiveChargingStationId();
    const clusterChargingStationIds = useChargingStationClusterIds();
    const currentMapContext = useCurrentMapContext();
    const mapBoundingBox = useBoundingBoxReadOnly();
    const mapCenter = useCenter();
    const mapZoom = useZoom();
    const activeAdditionalLayers = useActiveAdditionalLayers();
    const visibleWidgetIds = useVisibleWidgetIds();

    const props = {
        dispatch,
        activeAssetId,
        activePoiId,
        activeGeofenceId,
        activeChargingStationId,
        clusterChargingStationIds,
        currentMapContext,
        visibleWidgetIds,
        mapBoundingBox,
        mapCenter,
        mapZoom,
        activeAdditionalLayers,
    };

    const refProps = useRef(props);

    useEffect(() => {
        refProps.current = props;
    });

    // Register PostMessage event listener which take a callback function to be executed when the child
    // triggers a defined remote action
    useEffectOnce(() => {
        const listenerCallback = (event: Event) => receiveMessage(event, refProps);
        window.addEventListener('message', listenerCallback, false);
        return () => window.removeEventListener('message', listenerCallback, false);
    });

    // Tell all children current map context whenever it changes
    useEffect(() => {
        sendMessage(remoteActions.setMapContext(currentMapContext));
    }, [currentMapContext]);

    // Tell all children current active asset whenever it changes
    useEffect(() => {
        sendMessage(remoteActions.selectAsset({ id: activeAssetId }));
    }, [activeAssetId]);

    // Tell all children current active pois whenever it changes
    useEffect(() => {
        sendMessage(remoteActions.selectPois({ ids: activePoiId ? [activePoiId] : [] }));
    }, [activePoiId]);

    // Tell all children current active geofences whenever it changes
    useEffect(() => {
        sendMessage(remoteActions.selectGeofences({ ids: activeGeofenceId ? [activeGeofenceId] : [] }));
    }, [activeGeofenceId]);

    // Tell all children current active charging station whenever it changes
    useEffect(() => {
        sendMessage(
            remoteActions.chargingStationsSelected({ ids: activeChargingStationId ? [activeChargingStationId] : [] })
        );
    }, [activeChargingStationId]);

    // Tell all children current locale whenever it changes
    useEffect(() => {
        sendMessage(remoteActions.changeLocale(locale));
    }, [locale]);

    useEffect(() => {
        // console.log('Widgets visible: ', visibleWidgetIds);
        sendMessage(remoteActions.setVisibleWidgets(visibleWidgetIds));
    }, [visibleWidgetIds]);

    // Tell all children when the map layer changes
    useEffect(() => {
        sendMessage(
            remoteActions.activeAdditionalLayerChanged(activeAdditionalLayers as EventActiveAdditionalLayerChanged)
        );
    }, [activeAdditionalLayers]);

    return null;
};
