import { createAction } from '@reduxjs/toolkit';
import type {
    Address,
    Asset,
    CombinedAssetData,
    Driver,
    DrivingTimes,
    GeoBookingState,
    Geofence,
    Group,
    Pois,
    TransformedData,
} from './types';
import type { RootState } from '../configuration/setup/store';
import type { TREE_CATEGORY, TREE_POI_CATEGORY } from 'src/features/tree/treeSlice';

export const actions = {
    widgetShown: createAction<string>('monitor/WIDGET_SHOWN'),
    widgetHidden: createAction<string>('monitor/WIDGET_HIDDEN'),
    activeAssetChanged: createAction<string | undefined>('monitor/ACTIVE_ASSET_CHANGED'),
    activePoiChanged: createAction<string | null>('monitor/ACTIVE_POI_CHANGED'),
    activeGeofenceChanged: createAction<string | null>('monitor/ACTIVE_GEOFENCE_CHANGED'),
    activeChargingStationChanged: createAction<string | null>('monitor/ACTIVE_CHARGING_STATION_CHANGED'),
    activeSimplePayPoiChanged: createAction<string | null>('monitor/ACTIVE_SIMPLE_PAY_POI_CHANGED'),
    rawDataChanged: createAction<CombinedAssetData[]>('monitor/RAW_DATA_CHANGED'),
    groupsChanged: createAction<Group[]>('monitor/GROUPS_CHANGED'),
    assetsChanged: createAction<Asset[]>('monitor/ASSETS_CHANGED'),
    driversChanged: createAction<Driver[]>('monitor/DRIVERS_CHANGED'),
    drivingTimesChanged: createAction<DrivingTimes[]>('monitor/DRIVING_TIMES_CHANGED'),
    transformedDataChanged: createAction<TransformedData[]>('monitor/TRANSFORMED_DATA_CHANGED'),
    overallGeoBookingStateChanged: createAction<GeoBookingState>('monitor/OVERALL_GEO_BOOKING_STATE_CHANGED'),
    fetchInitialDataFailed: createAction<Error>('monitor/FETCH_INITIAL_DATA_FAILED'),
    fetchInitialDataRequested: createAction('monitor/FETCH_INITIAL_DATA_REQUESTED'),
    fetchInitialDataSuccess: createAction('monitor/FETCH_INITIAL_DATA_SUCCESS'),
    fetchGroupsRequested: createAction('monitor/FETCH_GROUPS_REQUESTED'),
    fetchGroupsFailed: createAction<Error>('monitor/FETCH_GROUPS_FAILED'),
    fetchGroupsSuccess: createAction('monitor/FETCH_GROUPS_SUCCESS'),
    fetchPoisFailed: createAction<Error>('monitor/FETCH_POIS_FAILED'),
    fetchPoisSuccess: createAction<Pois>('monitor/FETCH_POIS_SUCCESS'),
    fetchGeofencesRequested: createAction('monitor/FETCH_GEOFENCES_REQUESTED'),
    fetchGeofencesFailed: createAction<Error>('monitor/FETCH_GEOFENCES_FAILED'),
    fetchGeofencesSuccess: createAction<Geofence[]>('monitor/FETCH_GEOFENCES_SUCCESS'),
    fetchAddressesFailed: createAction<Error>('monitor/FETCH_ADDRESSES_FAILED'),
    fetchAddressesRequested: createAction('monitor/FETCH_ADDRESSES_REQUESTED'),
    fetchAddressesSuccess: createAction<Address[]>('monitor/FETCH_ADDRESSES_SUCCESS'),
    expandedAssetGroupsChanged: createAction<string[]>('monitor/EXPANDED_ASSET_GROUPS_CHANGED'),
    expandedDriverGroupsChanged: createAction<string[]>('monitor/EXPANDED_DRIVER_GROUPS_CHANGED'),
    selectedAssetsChanged: createAction<string[]>('monitor/SELECTED_ASSETS_CHANGED'),
    selectedAssetGroupsChanged: createAction<string[]>('monitor/SELECTED_ASSET_GROUPS_CHANGED'),
    selectedDriversChanged: createAction<string[]>('monitor/SELECTED_DRIVERS_CHANGED'),
    treeToggled: createAction('monitor/ASSET_TREE_TOGGLED'),
    treeCategoryChanged: createAction<TREE_CATEGORY>('monitor/TREE_CATEGORY_CHANGED'),
    treePoiCategoryChanged: createAction<TREE_POI_CATEGORY>('monitor/TREE_POI_CATEGORY_CHANGED'),
    mainViewChanged: createAction('monitor/MAIN_VIEW_CHANGED'),
    globalSearchValueChanged: createAction<string>('monitor/GLOBAL_SEARCH_VALUE_CHANGED'),
    routeChanged: createAction<RootState>('monitor/ROUTE_CHANGED'),
};
