import type { UserManager } from 'oidc-client-ts';
import { EVENT_USER_LANGUAGE_CHANGED, EVENT_USER_PROFILE_CHANGED } from '@rio-cloud/rio-user-menu-component';
import { datadogLogs } from '@datadog/browser-logs';

import { extractLanguage } from './lang/lang';
import { configureFetchDisplayMessages } from './lang/services';
import {
    configureMockUserManager,
    configureUserManager,
    createUserManager,
    type SessionRenewedResult,
} from './login/login';
import { accessToken } from './tokenHandling/accessToken';
import { attemptInitialSignIn } from './setup/oauth';
import { config } from '../config';
import { reportErrorToSentry } from './setup/sentry';
import { store } from './setup/store';
import { accessTokenStored, idTokenStored } from './tokenHandling/tokenSlice';
import { userProfileObtained, userSessionExpired, userSessionRenewed } from './login/loginSlice';
import { getLocale } from './lang/langSlice';
import { featureToggles } from './setup/featureToggles';

export type OAuthConfig = {
    onSessionExpired: () => void;
    onSessionRenewed: (result: SessionRenewedResult) => void;
};

// biome-ignore lint/suspicious/noExplicitAny: We don't know the type
const trace = featureToggles.tracing ? (...args: any) => console.log('[index]', ...args) : () => {};

export const main = async (renderApp: () => void) => {
    const fetchDisplayMessages = configureFetchDisplayMessages(store);

    // We want the `<html lang>` attribute to be synced with the
    // language currently displayed
    store.subscribe(() => {
        const lang = extractLanguage(getLocale(store.getState()));
        const html = document.querySelector('html');

        if (html && lang && html.getAttribute('lang') !== lang) {
            html.setAttribute('lang', lang);
        }
    });

    const oauthConfig = {
        onSessionExpired: () => {
            trace('index.onSessionExpired');
            accessToken.discardAccessToken();
            store.dispatch(userSessionExpired());
        },
        onSessionRenewed: (result: SessionRenewedResult) => {
            trace('index.onSessionRenewed', result);

            accessToken.saveAccessToken(result.accessToken);
            store.dispatch(accessTokenStored(result.accessToken));
            store.dispatch(idTokenStored(result.idToken));
            store.dispatch(userProfileObtained(result.profile));

            datadogLogs.setGlobalContextProperty('usr', {
                id: result.profile.sub,
                account: result.profile.account,
            });

            store.dispatch(userSessionRenewed());

            const preferredLocale = featureToggles.enforcedLocale || result.locale;
            // You will need to get the user language by yourself then
            // you may fetch the suitable messages from the CDN. Depending
            // on when and from where you fetch the user settings you might
            // want to employ a loading spinner while the request is ongoing.
            fetchDisplayMessages(preferredLocale);
        },
    };

    const testAccessToken = sessionStorage.getItem('test_access_token') ?? undefined; // enables mocking of authentication in production

    const userManager: UserManager =
        config.login.mockAuthorization || testAccessToken
            ? configureMockUserManager(oauthConfig, testAccessToken)
            : configureUserManager(oauthConfig, createUserManager());

    const signinSilent = userManager.signinSilent.bind(userManager);
    document.addEventListener(EVENT_USER_LANGUAGE_CHANGED, () => signinSilent());
    document.addEventListener(EVENT_USER_PROFILE_CHANGED, () => signinSilent());

    try {
        await userManager.clearStaleState();
        await attemptInitialSignIn(userManager);
        renderApp();
    } catch (error) {
        trace('could not start application', error);
        reportErrorToSentry(error);
    }
};
