import { motion } from '@rio-cloud/rio-uikit/framer-motion';
import { FormattedMessage } from 'react-intl';

type AnimatedFeedbackButtonProps = {
    onClick: () => void;
};

export const AnimatedFeedbackButton = ({ onClick }: AnimatedFeedbackButtonProps) => {
    const initialAnimationDelay = 2;
    const exitAnimationDelay = 3;
    const animationDuration = 0.25;

    return (
        <motion.div
            data-testid='animated-feedback-button'
            initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
                ease: 'easeInOut',
                duration: animationDuration,
                delay: initialAnimationDelay,
            }}
            className='position-fixed padding-left-5 padding-y-5 cursor-pointer overflow-hidden'
            style={{ left: '0px', bottom: '50px', zIndex: 991 }}
        >
            <div className='display-flex align-items-center' onClick={onClick}>
                <motion.div
                    initial={{ opacity: 1 }}
                    animate={{ opacity: 0 }}
                    transition={{
                        ease: 'easeInOut',
                        duration: animationDuration,
                        delay: initialAnimationDelay + animationDuration * 3 + exitAnimationDelay + 0.55,
                    }}
                    className='position-absolute inset-0 margin-5 bg-primary rounded-large'
                />
                <div className='width-40 aspect-ratio-1 display-grid place-items-center position-relative z-index-1'>
                    <motion.span
                        initial={{ y: 0, rotate: 0 }}
                        animate={{
                            y: [0, -3, 0],
                            rotate: [0, -10, 0],
                        }}
                        transition={{
                            duration: animationDuration * 3,
                            repeat: 2,
                            repeatType: 'loop',
                            delay: initialAnimationDelay,
                        }}
                        className='rioglyph rioglyph-comment text-size-h2 always-text-color-white'
                    />
                </div>
                <motion.span
                    initial={{ opacity: 1, width: 'auto', paddingRight: '15px' }}
                    animate={{
                        width: 0,
                        opacity: 0,
                        paddingRight: 0,
                    }}
                    transition={{
                        duration: animationDuration * 3,
                        delay: initialAnimationDelay + animationDuration * 3 + exitAnimationDelay,
                    }}
                    className='white-space-nowrap always-text-color-white z-index-1'
                >
                    <FormattedMessage id='intl-msg:common-message.component.featureVoting.feedbackButton' />
                </motion.span>
            </div>
        </motion.div>
    );
};
