import React, { type ErrorInfo, type ReactNode } from 'react';
import ErrorState from '@rio-cloud/rio-uikit/ErrorState';

interface ErrorBoundaryProps {
    children?: ReactNode;
}

interface ErrorBoundaryState {
    error: Error | null;
}

export class ErrorBoundary extends React.PureComponent<ErrorBoundaryProps, ErrorBoundaryState> {
    state: Readonly<ErrorBoundaryState> = {
        error: null,
    };

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.setState({ error });
    }

    render() {
        const { error } = this.state;
        const { children } = this.props;

        if (error) {
            // Render fallback UI
            return (
                <div className={'display-flex justify-content-center margin-top-15'}>
                    <ErrorState
                        headline={'Oops, something went wrong'}
                        message={
                            <div>
                                {'If this error persists, please contact the RIO Support Hotline:'}
                                <br />
                                <span className={'text-size-large margin-top-10'}>{'00800 / 22550746'}</span>
                            </div>
                        }
                    />
                </div>
            );
        }

        // When there's not an error, render children untouched
        return children;
    }
}
