import { useEffect, useRef } from 'react';
import type { RouteComponentProps } from 'react-router';

export const usePreviousLocation = (value: RouteComponentProps['location']) => {
    const ref = useRef<RouteComponentProps['location'] | null>(null);

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
};
