import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../../configuration/setup/store';
import { useAppSelector } from '../../../configuration/setup/hooks';

type AssetAdminLink = 'details' | 'group' | 'device' | undefined;

type AssetAdminState = {
    showAssetAdminDialogAt: AssetAdminLink;
};

export const defaultAssetAdminState: AssetAdminState = {
    showAssetAdminDialogAt: undefined,
};

export const assetAdminSlice = createSlice({
    name: 'assetAdmin',
    initialState: defaultAssetAdminState,
    reducers: {
        showAssetAdminDialogChangedAt: (state, action: PayloadAction<AssetAdminLink>) => {
            state.showAssetAdminDialogAt = action.payload;
        },
    },
});

export const { showAssetAdminDialogChangedAt } = assetAdminSlice.actions;

export const getShowAssetAdminDialogAt = (state: RootState): boolean => state.app.assetAdmin.showAssetAdminDialogAt;

export const useShowAdminDialogAt = () => useAppSelector(getShowAssetAdminDialogAt);

export default assetAdminSlice.reducer;
