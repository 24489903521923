import { createAction } from '@reduxjs/toolkit';
import type { GlobalSearchMarker } from '../map/mapSlice';

export const treeActions = {
    searchResultSelected: createAction<GlobalSearchMarker>('monitor/tree/SEARCH_RESULT_SELECTED'),
    emptyGroupsToggled: createAction('monitor/tree/EMPTY_GROUPS_TOGGLED'),
    assetGroupsToggled: createAction('monitor/tree/ASSET_GROUPS_TOGGLED'),
    driverGroupsToggled: createAction('monitor/tree/DRIVER_GROUPS_TOGGLED'),
    fuelTypeToggled: createAction('monitor/tree/FUEL_TYPE_TOGGLED'),
};
