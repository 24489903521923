const localStorage = window.localStorage;

const STORAGE_PREFIX = 'livemonitor.';

const save = (key: string, value: string | boolean, prefix = STORAGE_PREFIX) => {
    try {
        localStorage.setItem(`${prefix}${key}`, JSON.stringify(value));
        return value;
    } catch (_) {
        // Intentionally left blank
    }
};

const removeItem = (key: string, prefix = STORAGE_PREFIX) => {
    try {
        localStorage.removeItem(`${prefix}${key}`);
    } catch (_) {
        // Intentionally left blank
    }
};

const load = (key: string, prefix: string = STORAGE_PREFIX): string | undefined => {
    try {
        const item = localStorage.getItem(`${prefix}${key}`);
        return item && JSON.parse(item);
    } catch (_) {
        // Intentionally left blank
    }
};

export const storage = { save, load, removeItem };
