import { useAppSelector } from '../../configuration/setup/hooks';
import {
    showTableSettingsDialog,
    getChunkCounter,
    getViewType,
    getActivityFilter,
    getAssetFilter,
    getNotificationFilter,
} from '../table/tableSelectors';
import { getSidebarTabId } from './sidebarSelectors';

export const useActiveTab = () => useAppSelector(getSidebarTabId);

export const useShowTableSettingsDialog = () => useAppSelector(showTableSettingsDialog);
export const useChunkCounter = () => useAppSelector(getChunkCounter);
export const useViewType = () => useAppSelector(getViewType);
export const useActivityFilter = () => useAppSelector(getActivityFilter);
export const useAssetFilter = () => useAppSelector(getAssetFilter);
export const useNotificationFilter = () => useAppSelector(getNotificationFilter);
