import classnames from 'classnames';
import { NotificationStates } from '../../../services/types';

type NotificationBadgeProps = {
    level: string;
    amount: number;
};

export const NotificationBadge = ({ level = '', amount = 0 }: NotificationBadgeProps) => {
    if (amount === 0) {
        return null;
    }

    const iconClassNames = classnames(
        'badge padding-y-4',
        level === NotificationStates.exception && 'bg-danger',
        level === NotificationStates.warning && 'bg-warning'
    );
    return (
        <span className={iconClassNames} data-testid={'notification-badge'}>
            {amount}
        </span>
    );
};
