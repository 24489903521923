const getHeaders = (token: string) => ({
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
});

const getOptions = (options?: object, token?: string) => {
    if (token) {
        return {
            ...options,
            headers: getHeaders(token),
        };
    }
    return options;
};

const checkStatus = (url: string) => (response: Response) => {
    if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response);
    }
    if (response.status === 401) {
        return Promise.reject(
            new Error(`401: Unauthorized for URL=${url} with reponse status text=${response.statusText}`)
        );
    }
    return Promise.reject(new Error(`${response.status} Backend error: ${response.statusText}`));
};

const toJSON = (response: Response) => response.json();

const handleError = (error: Error) => Promise.reject(error);

export const fetchData = async (url: string, token?: string, options?: object) => {
    const handleCheckStatus = checkStatus(url);

    return fetch(url, getOptions(options, token)).then(handleCheckStatus).then(toJSON).catch(handleError);
};
