import { useEffect } from 'react';
import { enableSupportMarker } from '@rio-cloud/rio-uikit/SupportMarker';

import { featureToggles } from '../configuration/setup/featureToggles';

// Enable all support markers to be shown
export const useSupportMarkers = () => {
    const isSupport = featureToggles.support;

    useEffect(() => {
        if (isSupport) {
            enableSupportMarker(isSupport);
        }
    }, [isSupport]);
};
