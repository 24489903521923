import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import isEmpty from 'lodash/fp/isEmpty';

import { actions as appActions } from '../../services/actions';
import { remoteActions } from '../widgets/remoteActions';
import { sidebarActions } from '../sidebar/sidebarActions';
import { ASSET_TAB_HISTORY } from '../sidebar/sidebarReducer';
import { treeActions } from '../tree/treeActions';
import type { CoordinatesShort } from '../../services/types';
import type {
    ChargingStation,
    EventRenderMarkers,
    EventRenderRoute,
    EventRenderShape,
    EventRenderChargingStations,
    Marker,
    Shape,
    EventRenderSimplePayPois,
    SimplePayPoi,
} from '../widgets/remoteActionTypes';
import { DEFAULT_CENTER, DEFAULT_ZOOM } from '../../data/dataDefinition';

const getTimeInMilliseconds = () => Date.now();

export const MapContext = {
    MAP_CONTEXT_DEFAULT: 'default',
    MAP_CONTEXT_HISTORY: 'history',
    MAP_CONTEXT_ROUTEPLANNING: 'routeplanning',
} as const;

export type MapContextType = (typeof MapContext)[keyof typeof MapContext];

type ContextFeatures = {
    renderCluster?: boolean;
    renderAssets?: boolean;
    renderCurrentAsset?: boolean;
    remoteZoomTo?: boolean;
    lockOnAsset?: boolean;
    centerMapOnAssetTreeSelection?: boolean;
    centerActiveAssetButton: boolean;
    markers: Marker[];
    shapes: Shape[];
    chargingStations: ChargingStation[];
    simplePayPois: SimplePayPoi[];
    route: object; // FIXME
};

export const defaultContextFeatures: ContextFeatures = {
    renderCluster: true,
    renderAssets: true,
    remoteZoomTo: false,
    lockOnAsset: true,
    centerActiveAssetButton: true,
    centerMapOnAssetTreeSelection: true,
    markers: [],
    shapes: [],
    chargingStations: [],
    simplePayPois: [],
    route: {},
    /* Responsible whether the currently selected asset will be rendered
     * on a dedicated layer on map or not. Usually relevant for widgets or other contexts */
    renderCurrentAsset: false,
};

export type ContextMap = {
    [MapContext.MAP_CONTEXT_DEFAULT]: ContextFeatures;
    [MapContext.MAP_CONTEXT_HISTORY]: ContextFeatures;
    [MapContext.MAP_CONTEXT_ROUTEPLANNING]: ContextFeatures;
};

export const contextMap: ContextMap = {
    [MapContext.MAP_CONTEXT_DEFAULT]: {
        ...defaultContextFeatures,
    },
    [MapContext.MAP_CONTEXT_HISTORY]: {
        ...defaultContextFeatures,
        renderCluster: false,
        renderAssets: false,
        renderCurrentAsset: true,
        remoteZoomTo: true,
        lockOnAsset: false,
        centerMapOnAssetTreeSelection: false,
    },
    [MapContext.MAP_CONTEXT_ROUTEPLANNING]: {
        ...defaultContextFeatures,
    },
};

export const MapType = {
    TYPE_DEFAULT: 'DEFAULT',
    TYPE_FLEET_STYLE: 'FLEET_STYLE',
    TYPE_SATELLITE: 'SATELLITE',
    TYPE_TERRAIN: 'TERRAIN',
    TYPE_NIGHT: 'NIGHT',
} as const;

export type MapType = (typeof MapType)[keyof typeof MapType];

export const MapLayer = {
    MAP_LAYER_INCIDENTS: 'INCIDENTS',
    MAP_LAYER_TRAFFIC: 'TRAFFIC',
    MAP_LAYER_ROAD_RESTRICTIONS: 'ROAD_RESTRICTIONS',
} as const;

export type MapLayer = (typeof MapLayer)[keyof typeof MapLayer];

type MapRendering = {
    enableWebGL: boolean;
    enableDevicePixelRatio: boolean;
};

export type BoundingBox = {
    topLeft: CoordinatesShort;
    bottomRight: CoordinatesShort;
};

export type GlobalSearchMarker = {
    markerProps: { name: string }; // usually contains only name
    position: CoordinatesShort;
    type: string; // FIXME: use "addressType"
};

type MapViewType = { center: CoordinatesShort; zoom: number; boundingBox?: BoundingBox };

type MapState = {
    mapLoaded: boolean;
    zoom: number | undefined;
    center: CoordinatesShort | undefined;
    // Used to control the map
    boundingBox: BoundingBox | undefined;
    // Used to notify the widgets
    boundingBoxReadOnly: BoundingBox | undefined;
    lockOnAsset: boolean;
    currentContext: MapContextType;
    contextMap: ContextMap;
    mapType: MapType;
    selectedClusterAssetIds: string[];
    mapContainerSizeTimestamp: number;
    showCluster: boolean;
    globalSearchMarker: GlobalSearchMarker | undefined;
    showWorkshopPois: boolean;
    showCustomerPois: boolean;
    showGeofences: boolean;
    showChargingStations: boolean;
    showSimplePayPois: boolean;
    selectedClusterChargingStationIds: string[];
    selectedClusterSimplePayPoiIds: string[];
    mapLayers: MapLayer[];
    mapRendering: MapRendering;
};

export const defaultMapState: MapState = {
    mapLoaded: false,
    zoom: DEFAULT_ZOOM,
    center: DEFAULT_CENTER,
    boundingBox: undefined,
    boundingBoxReadOnly: undefined,
    lockOnAsset: false,
    currentContext: MapContext.MAP_CONTEXT_DEFAULT,
    contextMap: contextMap,
    mapType: MapType.TYPE_DEFAULT,
    selectedClusterAssetIds: [],
    mapContainerSizeTimestamp: getTimeInMilliseconds(),
    showCluster: true,
    globalSearchMarker: undefined,
    showWorkshopPois: false,
    showCustomerPois: false,
    showGeofences: false,
    showChargingStations: false,
    showSimplePayPois: false,
    selectedClusterChargingStationIds: [],
    selectedClusterSimplePayPoiIds: [],
    mapLayers: [],
    mapRendering: {
        enableWebGL: !document.cookie.includes('performance_mode'),
        enableDevicePixelRatio: !document.cookie.includes('performance_mode'),
    },
};

export const getMapContextDefaultSettings = () => ({
    currentContext: MapContext.MAP_CONTEXT_DEFAULT,
});

export const getMapContextHistorySettings = () => ({
    currentContext: MapContext.MAP_CONTEXT_HISTORY,
    lockOnAsset: false,
});

export const getCorrespondingSidebarTabContext = (value: string) => {
    switch (value) {
        case ASSET_TAB_HISTORY:
            return MapContext.MAP_CONTEXT_HISTORY;
        default:
            return MapContext.MAP_CONTEXT_DEFAULT;
    }
};

const resetSelectedClusterIds = (state: MapState) => {
    return {
        ...state,
        selectedClusterAssetIds: [],
        selectedClusterChargingStationIds: [],
        selectedClusterSimplePayPoiIds: [],
    };
};

export const mapSlice = createSlice({
    name: 'map',
    initialState: defaultMapState,
    reducers: {
        mapLoaded: state => {
            state.mapLoaded = true;
        },
        clusterSelected: (state, action: PayloadAction<string[]>) => {
            state.selectedClusterAssetIds = action.payload;
            state.selectedClusterChargingStationIds = [];
            state.selectedClusterSimplePayPoiIds = [];
        },
        clusterDeselected: state => {
            state.selectedClusterAssetIds = [];
            state.selectedClusterChargingStationIds = [];
            state.selectedClusterSimplePayPoiIds = [];
        },
        centerChanged: (state, action: PayloadAction<CoordinatesShort | undefined>) => {
            state.center = action.payload;
        },
        zoomChanged: (state, action: PayloadAction<number | undefined>) => {
            state.zoom = action.payload;
        },
        mapTypeChanged: (state, action: PayloadAction<MapType>) => {
            state.mapType = action.payload;
        },
        mapLayersChanged: (state, action: PayloadAction<MapLayer[]>) => {
            state.mapLayers = action.payload;
        },
        showClusterChanged: (state, action: PayloadAction<boolean>) => {
            state.showCluster = action.payload;
        },
        workshopPoisToggled: (state, action: PayloadAction<boolean>) => {
            state.showWorkshopPois = action.payload;
        },
        customerPoisToggled: (state, action: PayloadAction<boolean>) => {
            state.showCustomerPois = action.payload;
        },
        geofencesToggled: (state, action: PayloadAction<boolean>) => {
            state.showGeofences = action.payload;
        },
        chargingStationsToggled: (state, action: PayloadAction<boolean>) => {
            state.showChargingStations = action.payload;
        },
        simplePayPoisToggled: (state, action: PayloadAction<boolean>) => {
            state.showSimplePayPois = action.payload;
        },
        chargingStationClusterSelected: (state, action: PayloadAction<string[]>) => {
            state.selectedClusterChargingStationIds = action.payload;
            state.selectedClusterAssetIds = [];
            state.selectedClusterSimplePayPoiIds = [];
        },
        simplePayPoiClusterSelected: (state, action: PayloadAction<string[]>) => {
            state.selectedClusterChargingStationIds = [];
            state.selectedClusterAssetIds = [];
            state.selectedClusterSimplePayPoiIds = action.payload;
        },
        assetLockOnMapToggled: (state, action: PayloadAction<boolean>) => {
            state.lockOnAsset = action.payload;
        },
        mapViewChanged: (state, action: PayloadAction<MapViewType>) => {
            state.center = action.payload.center;
            state.zoom = action.payload.zoom;
            state.boundingBox = action.payload.boundingBox;
        },
        boundingBoxChanged: (state, action: PayloadAction<BoundingBox | undefined>) => {
            state.boundingBox = action.payload;
        },
        boundingBoxChangedReadOnly: (state, action: PayloadAction<BoundingBox | undefined>) => {
            state.boundingBoxReadOnly = action.payload;
        },
        mapContainerSizeChanged: state => {
            state.mapContainerSizeTimestamp = getTimeInMilliseconds();
        },
        mapRenderingChanged: (state, action: PayloadAction<MapRendering>) => {
            state.mapRendering = action.payload;
        },
        currentContextChanged: (state, action: PayloadAction<MapContextType>) => {
            state.currentContext = action.payload;

            // Disable locking when switching to history context
            if (action.payload === MapContext.MAP_CONTEXT_HISTORY) {
                state.lockOnAsset = false;
            }
        },
    },
    extraReducers: builder => {
        // Remote actions
        builder.addCase(remoteActions.renderRoute, (state, action: PayloadAction<EventRenderRoute>) => {
            if (action.payload.context) {
                state.contextMap = {
                    ...state.contextMap,
                    [action.payload.context as string]: {
                        ...state.contextMap[action.payload.context],
                        markers: action.payload.markers,
                        route: { ...action.payload, markers: undefined },
                    },
                };
            }
        });
        builder.addCase(remoteActions.renderShape, (state, action: PayloadAction<EventRenderShape>) => {
            if (action.payload.context) {
                state.contextMap = {
                    ...state.contextMap,
                    [action.payload.context as string]: {
                        ...state.contextMap[action.payload.context],
                        shapes: action.payload.shapes,
                    },
                };
            }
        });
        builder.addCase(remoteActions.renderMapMarkers, (state, action: PayloadAction<EventRenderMarkers>) => {
            if (action.payload.context) {
                state.contextMap = {
                    ...state.contextMap,
                    [action.payload.context as string]: {
                        ...state.contextMap[action.payload.context],
                        markers: action.payload.markers,
                    },
                };
            }
        });
        builder.addCase(
            remoteActions.renderChargingStations,
            (state, action: PayloadAction<EventRenderChargingStations>) => {
                if (action.payload.context) {
                    state.contextMap = {
                        ...state.contextMap,
                        [action.payload.context as string]: {
                            ...state.contextMap[action.payload.context],
                            chargingStations: action.payload.chargingStations,
                        },
                    };
                }
            }
        );
        builder.addCase(remoteActions.renderSimplePayPois, (state, action: PayloadAction<EventRenderSimplePayPois>) => {
            if (action.payload.context) {
                state.contextMap = {
                    ...state.contextMap,
                    [action.payload.context as string]: {
                        ...state.contextMap[action.payload.context],
                        simplePayPois: action.payload.simplePayPois,
                    },
                };
            }
        });

        // App actions
        builder.addCase(appActions.globalSearchValueChanged, (state, action: PayloadAction<string>) => {
            if (isEmpty(action.payload)) {
                state.globalSearchMarker = defaultMapState.globalSearchMarker;
            }
        });
        builder.addCase(appActions.activePoiChanged, resetSelectedClusterIds);
        builder.addCase(appActions.activeGeofenceChanged, resetSelectedClusterIds);
        builder.addCase(appActions.activeChargingStationChanged, resetSelectedClusterIds);
        builder.addCase(appActions.activeSimplePayPoiChanged, resetSelectedClusterIds);

        builder.addCase(appActions.activeAssetChanged, (state, action: PayloadAction<unknown>) => {
            // When an asset is deselected, reset the map context to "default" again so in case
            // the context was changed like for instance to asset history all assets are shown again
            if (!action.payload) {
                state.currentContext = MapContext.MAP_CONTEXT_DEFAULT;

                // Update the map timestamp so it resizes. This is needed as the asset sidebar is closed when an
                // asset is deselected
                state.mapContainerSizeTimestamp = getTimeInMilliseconds();
            }

            // Reset cluster selection when an asset gets selected
            if (action.payload) {
                state.selectedClusterAssetIds = [];
                state.selectedClusterChargingStationIds = [];
            }
        });

        // Sidebar actions
        builder.addCase(sidebarActions.sidebarTabSelectionChanged, (state, action: PayloadAction<unknown>) => {
            state.currentContext = getCorrespondingSidebarTabContext(action.payload as string);
        });
        builder.addCase(sidebarActions.assetSelectedFromClusterSidebar, state => {
            state.selectedClusterAssetIds = [];
            state.selectedClusterChargingStationIds = [];
        });

        // Tree actions: when clicking on search address in the tree
        builder.addCase(treeActions.searchResultSelected, (state, action: PayloadAction<GlobalSearchMarker>) => {
            state.globalSearchMarker = action.payload;
            state.center = action.payload.position;
        });
    },
});

export const {
    mapLoaded,
    clusterSelected,
    clusterDeselected,
    centerChanged,
    zoomChanged,
    mapTypeChanged,
    mapLayersChanged,
    showClusterChanged,
    workshopPoisToggled,
    customerPoisToggled,
    geofencesToggled,
    chargingStationsToggled,
    simplePayPoisToggled,
    chargingStationClusterSelected,
    simplePayPoiClusterSelected,
    assetLockOnMapToggled,
    mapViewChanged,
    boundingBoxChanged,
    boundingBoxChangedReadOnly,
    mapContainerSizeChanged,
    mapRenderingChanged,
    currentContextChanged,
} = mapSlice.actions;

export { appActions };

export default mapSlice.reducer;
