import { createSelector } from 'reselect';
import isEmpty from 'lodash/fp/isEmpty';
import toPairs from 'lodash/fp/toPairs';
import filter from 'lodash/fp/filter';
import map from 'lodash/fp/map';
import flow from 'lodash/fp/flow';
import some from 'lodash/fp/some';

import type { RootState } from '../../configuration/setup/store';
import type {
    CombinedAssetData,
    Address,
    Asset,
    Driver,
    DrivingTimes,
    Geofence,
    Group,
    Poi,
    Pois,
    TransformedData,
    GeoBookingState,
} from '../../services/types';
import type { ChargingStation, SimplePayPoi } from '../widgets/remoteActionTypes';

export const isFetchInitialDataRequested = (state: RootState): boolean => state.app?.data.fetchInitialDataRequested;
export const isFetchInitialDataFailed = (state: RootState): boolean => state.app?.data.fetchInitialDataFailed;

export const isFetchGroupsFailed = (state: RootState): boolean => state.app?.data.fetchGroupsFailed;

export const isFetchPoisFailed = (state: RootState): boolean => state.app?.data.fetchPoisFailed;

const getStructuredPois = (state: RootState): Pois => state.app?.data.pois;
export const getWorkshopPois = (state: RootState): Poi[] => getStructuredPois(state).workshopPois;
export const getCustomerPois = (state: RootState): Poi[] => getStructuredPois(state).customerPois;
export const getPois = (state: RootState): Poi[] => {
    const pois = getStructuredPois(state);
    return [...pois.customerPois, ...pois.workshopPois];
};

export const isFetchGeofencesFailed = (state: RootState): boolean => state.app?.data.fetchGeofencesFailed;
export const getGeofences = (state: RootState): Geofence[] => state.app?.data.geofences;

export const isFetchAddressesRequested = (state: RootState): boolean => state.app?.data.fetchAddressesRequested;
export const getAddresses = (state: RootState): Address[] => state.app?.data.addresses;

export const getOverallGeoBookingState = (state: RootState): GeoBookingState => state.app?.data.overallGeoBookingState;

export const getAssets = (state: RootState): Asset[] => state.app?.data.assets;
export const getDrivers = (state: RootState): Driver[] => state.app?.data.drivers;
export const getGroups = (state: RootState): Group[] => state.app?.data.groups;
export const getDrivingTimes = (state: RootState): DrivingTimes[] => state.app?.data.drivingTimes;

export const getData = (state: RootState): CombinedAssetData[] => state.app?.data.rawData;
export const getTransformedData = (state: RootState): TransformedData[] => state.app?.data.transformedData || [];

export const getActiveAssetId = (state: RootState): string => state.app?.data.activeAssetId;
export const getActiveAsset = (state: RootState): TransformedData | undefined => {
    const assetId = getActiveAssetId(state);
    const transformedData = getTransformedData(state);
    return transformedData?.find((item: TransformedData) => item.dataKey === assetId);
};

export const getActivePoiId = (state: RootState): string => state.app?.data.activePoiId;
export const getActivePoi = createSelector(
    getActivePoiId,
    getPois,
    (poiId, pois) => !isEmpty(pois) && pois.find(item => item.id === poiId)
);

export const getActiveGeofenceId = (state: RootState): string => state.app?.data.activeGeofenceId;
export const getActiveGeofence = createSelector(
    getActiveGeofenceId,
    getGeofences,
    (geofenceId, geofences) => !isEmpty(geofences) && geofences.find((item: Geofence) => item.id === geofenceId)
);

export const getActiveChargingStationId = (state: RootState): string => state.app?.data.activeChargingStationId;

export const getChargingStation = (state: RootState): ChargingStation[] =>
    state.app?.map.contextMap.default.chargingStations ?? [];

export const getActiveChargingStation = createSelector(
    getChargingStation,
    getActiveChargingStationId,
    (chargingStations, activeChargingStationId) => {
        return chargingStations.find((item: ChargingStation) => item.id === activeChargingStationId);
    }
);

export const getActiveSimplePayPoiId = (state: RootState): string => state.app?.data.activeSimplePayPoiId;

export const getSimplePayPois = (state: RootState): SimplePayPoi[] =>
    state.app?.map.contextMap.default.simplePayPois ?? [];

export const getActiveSimplePayPoi = createSelector(getSimplePayPois, getActiveSimplePayPoiId, (pois, activePoiId) => {
    return pois.find((item: SimplePayPoi) => item.id === activePoiId);
});

export const getOnboardingState = (state: RootState) => state.app?.onboarding;
export const areOnboardingTipsShown = createSelector(getOnboardingState, state => some(item => item === true)(state));

export const getSelectedDriverIds = (state: RootState): string[] => state.app?.tree.selectedDriverIds;
export const getSelectedAssetGroupIds = (state: RootState): string[] => state.app?.tree.selectedAssetGroupIds;
export const getSelectedAssetIds = (state: RootState): string[] => state.app?.tree.selectedAssetIds;

export const getWidgets = (state: RootState) => state.app?.widgets;
export const getVisibleWidgetIds = createSelector(getWidgets, widgets =>
    flow(
        toPairs,
        filter(([, widget]) => widget.visible),
        map(([id]) => id)
    )(widgets)
);

export const isMobile = (): boolean => document?.documentElement.classList.contains('ua-mobile') || false;

export const getChatTotalUnreadMessageCount = (state: RootState): number => state.app?.data.chatTotalUnreadMessageCount;
