import { config } from '../../config';
import { trace } from './trace';
import { routeStorage } from '../login/storage';
import { reportErrorToSentry } from './sentry';
import type { UserManager } from 'oidc-client-ts';

const saveCurrentRoute = () => {
    const initialRoute = [window.location.hash, window.location.search].join('').replace(/^#/u, '');

    routeStorage.saveRoute(initialRoute);

    trace('saving initial route', initialRoute);
};

export const attemptInitialSignIn = async (userManager: UserManager) => {
    const isFreshRedirect = window.location.href.includes('redirected');

    try {
        const user = await userManager.signinSilent();
        const initialRoute = routeStorage.getRoute();

        trace('initialRoute lookup', initialRoute);
        if (initialRoute && isFreshRedirect) {
            trace(`Go to location "${initialRoute}"`);

            // if the initialRoute already starts with a leading "/" we don't need to add it again
            const prefix = initialRoute.startsWith('/') ? '#' : '#/';

            window.location.replace(`${prefix}${initialRoute}`);
        }

        routeStorage.discardRoute();

        return Promise.resolve(user);
    } catch (error) {
        trace('oidc.signinSilent failed, trying page redirect...', error);

        if (config.login.preventRedirect || sessionStorage?.getItem('test_access_token')) {
            console.warn('[feature/login] redirect prevented due to config. Error was', error);
        } else if (isFreshRedirect) {
            trace('oidc.signinSilent.error', 'redirect prevented due to suspicious signin error', error);
            routeStorage.discardRoute();
            reportErrorToSentry(error);
        } else {
            saveCurrentRoute();
            await userManager.signinRedirect();
        }

        trace('auth problem?', error);
        throw error as Error;
    }
};
