import packageJson from '../package.json';

const asBool = (value: string | undefined): boolean => value === 'true';
const asNumber = (value: string | undefined): number => (value ? Number.parseInt(value, 10) : 0);

export interface ConfigState {
    backend: {
        AUTHENTICATION_SERVICE: string | undefined;
        USERADMIN_SERVICE: string | undefined;
        USER_SETTINGS_SERVICE: string | undefined;
        LIVEMONITOR_SERVICE: string | undefined;
        ASSET_LIVE_STATE_SERVICE: string | undefined;
        DRIVING_TIMES_SERVICE: string | undefined;
        CONTACT_FORM_API: string | undefined;
        CONTACT_FORM_API_KEY: string | undefined;
        HERE_REV_GEOCODING_SERVICE: string | undefined;
        HERE_AUTOSUGGEST_SERVICE: string | undefined;
        POLL_INTERVAL: number | undefined;
        RIO_MENU: string | undefined;
    };
    featureToggles: {
        splitApiKey: string | undefined;
    };
    homeRoute: string | undefined;
    id: string | undefined;
    releaseVersion: string;
    login: {
        authority: string | undefined;
        clientId: string | undefined;
        oauthScope: string[];
        mockAuthorization: boolean;
        mockLocale: string | undefined;
        mockTenant: string | undefined;
        preventRedirect: boolean;
        redirectUri: string | undefined;
        silentRedirectUri: string | undefined;
    };
    serviceVersion: string;
    serviceEnvironment: string;
    enableMockServer: boolean;
    logoutUri: string | undefined;
    sentryToken: string | undefined;
    sentryModuleName: string;
    hereBaseUrl: string;
    hereApiKey: string;
}

export const config: ConfigState = {
    backend: {
        AUTHENTICATION_SERVICE: import.meta.env.VITE_AUTHENTICATION_SERVICE,
        USERADMIN_SERVICE: import.meta.env.VITE_USERADMIN_SERVICE,
        USER_SETTINGS_SERVICE: import.meta.env.VITE_USER_SETTINGS_SERVICE,
        LIVEMONITOR_SERVICE: import.meta.env.VITE_LIVEMONITOR_SERVICE,
        ASSET_LIVE_STATE_SERVICE: import.meta.env.VITE_ASSET_LIVE_STATE_SERVICE,
        DRIVING_TIMES_SERVICE: import.meta.env.VITE_DRIVING_TIMES_SERVICE,
        CONTACT_FORM_API: import.meta.env.VITE_CONTACT_FORM,
        CONTACT_FORM_API_KEY: import.meta.env.VITE_CONTACT_FORM_API_KEY,
        HERE_REV_GEOCODING_SERVICE: import.meta.env.VITE_HERE_REV_GEOCODING_SERVICE,
        HERE_AUTOSUGGEST_SERVICE: import.meta.env.VITE_HERE_AUTOSUGGEST_SERVICE,
        POLL_INTERVAL: asNumber(import.meta.env.VITE_POLL_INTERVAL),
        RIO_MENU: 'https://menu.rio.cloud',
    },
    featureToggles: {
        splitApiKey: import.meta.env.VITE_FEATURE_TOGGLE_SPLIT_API_KEY,
    },
    homeRoute: import.meta.env.VITE_HOME_ROUTE,
    id: import.meta.env.VITE_ID,
    releaseVersion: import.meta.env.VITE_RELEASE_VERSION,
    login: {
        authority: import.meta.env.VITE_LOGIN_AUTHORITY,
        clientId: '924d499e-53a5-42bd-90a7-7464b0ead11f',
        oauthScope: [
            'openid',
            'profile',
            'email',
            'user-managment.read',
            'fleetadmin.read',
            'asset-administration.read',
            'drivers.read',
            'pois.read',
            'marketplace.read',
            'location.read',
            'tags.read',
            'map.read',
            'geofence.read',
            'livemonitor.read',
        ],
        mockAuthorization: asBool(import.meta.env.VITE_LOGIN_MOCK_AUTHORIZATION),
        mockLocale: import.meta.env.VITE_LOGIN_MOCK_LOCALE,
        mockTenant: import.meta.env.VITE_LOGIN_MOCK_TENANT,
        preventRedirect: asBool(import.meta.env.VITE_LOGIN_PREVENT_REDIRECT),
        redirectUri: import.meta.env.VITE_LOGIN_REDIRECT_URI,
        silentRedirectUri: import.meta.env.VITE_LOGIN_SILENT_REDIRECT_URI,
    },
    serviceVersion: packageJson.version,
    serviceEnvironment: import.meta.env.MODE,
    enableMockServer: import.meta.env.DEV,
    logoutUri: import.meta.env.VITE_LOGOUT_URI,
    sentryToken: import.meta.env.VITE_SENTRY_DSN,
    sentryModuleName: 'livemonitor-web',
    hereBaseUrl: 'https://js.api.here.com/v3/3.1/',
    hereApiKey: import.meta.env.VITE_HERE_API_KEY,
};
