import type { Dispatch } from 'redux';

import { fetchData } from '../configuration/setup/fetch';
import { actions } from './actions';
import { config } from '../config';
import { getAccessToken } from '../configuration/tokenHandling/tokenSlice';
import type { RootState } from '../configuration/setup/store';

export const fetchGeofences = async (dispatch: Dispatch, getState: () => RootState) => {
    try {
        dispatch(actions.fetchGeofencesRequested());

        const url = `${config.backend.LIVEMONITOR_SERVICE}/geofences`;
        const result = await fetchData(url, getAccessToken(getState()));

        const geofences = result.items;

        dispatch(actions.fetchGeofencesSuccess(geofences));
    } catch (error: unknown) {
        dispatch(actions.fetchGeofencesFailed(error as Error));
    }
};
