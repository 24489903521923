import { datadogLogs } from '@datadog/browser-logs';

import { isLocalhost } from '../../utils/utils';

if (!isLocalhost()) {
    datadogLogs.init({
        clientToken: 'pubab55d5c84e1d32077444f038c9785650',
        forwardErrorsToLogs: true,
        site: 'datadoghq.eu',
        sampleRate: 100,
        version: '0.6.0',
        service: 'livemonitor-web',
        env: 'prod',
        beforeSend: event => {
            if (event.view) {
                if (event.view.url) {
                    event.view.url = event.view.url.replace(/access_token=[^&]*/, 'access_token=REDACTED');
                    event.view.url = event.view.url.replace(/id_token=[^&]*/, 'id_token=REDACTED');
                }
                if (event.view.referrer) {
                    event.view.referrer = event.view.referrer.replace(/access_token=[^&]*/, 'access_token=REDACTED');
                    event.view.referrer = event.view.referrer.replace(/id_token=[^&]*/, 'id_token=REDACTED');
                }
            }
            if (event.http?.url) {
                event.http.url = event.http.url.replace(/access_token=[^&]*/, 'access_token=REDACTED');
                event.http.url = event.http.url.replace(/id_token=[^&]*/, 'id_token=REDACTED');
            }
            if (event.message) {
                event.message = event.message.replace(/access_token=[^&]*/, 'access_token=REDACTED');
                event.message = event.message.replace(/id_token=[^&]*/, 'id_token=REDACTED');
            }
            if (event.error?.stack) {
                event.error.stack = event.error.stack.replace(/access_token=[^&]*/, 'access_token=REDACTED');
                event.error.stack = event.error.stack.replace(/id_token=[^&]*/, 'id_token=REDACTED');
            }
        },
    });
    datadogLogs.setGlobalContextProperty('team_identifier', import.meta.env.VITE_TEAM_IDENTIFIER);
    datadogLogs.setGlobalContextProperty('account_id', import.meta.env.VITE_ACCOUNT_ID);
}
