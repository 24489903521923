import { FormattedMessage } from 'react-intl';
import ErrorState from '@rio-cloud/rio-uikit/ErrorState';

export const ErrorMessage = () => (
    <div className={'margin-top-15pct'}>
        <ErrorState
            headline={<FormattedMessage id={'intl-msg:common-message.error.generic.headline'} />}
            message={<FormattedMessage id={'intl-msg:common-message.error.generic.message'} />}
        />
    </div>
);
