import { type Dispatch, useEffect } from 'react';
import type { AnyAction } from 'redux';
import isEmpty from 'lodash/fp/isEmpty';
import includes from 'lodash/fp/includes';

import { storage } from '../../configuration/setup/storage';
import { MAP_PATH } from '../../routes/routes';
import { useAppDispatch } from '../../configuration/setup/hooks';
import { config } from '../../config';
import { serviceInfoActions } from './serviceInfoActions';
import { chapters } from './chapters';

export type QueryParams = {
    info?: string;
};

const isNewVersion = (version: string) => {
    if (!version) {
        return true;
    }
    const localVersionNumbers = version.split('.');
    const currentVersionNumbers = config.releaseVersion.split('.');

    return !(
        currentVersionNumbers[0] === localVersionNumbers[0] &&
        currentVersionNumbers[1] === localVersionNumbers[1] &&
        currentVersionNumbers[2] === localVersionNumbers[2]
    );
};

export const triggerServiceInfoDialog = (dispatch: Dispatch<AnyAction>, hash: string) => {
    const isWelcomeDisabled = includes('info=false', hash);
    const isMapUrl = includes(MAP_PATH, hash);

    // Check the location path for map view and a dedicated info flag to disable the service info dialog
    if (!isMapUrl || isWelcomeDisabled) {
        return;
    }

    const releaseVersion = storage.load('releaseVersion');

    if (isEmpty(storage.load('welcome')) && isEmpty(releaseVersion)) {
        dispatch(serviceInfoActions.serviceInfoDialogOpenedAtChapter());
    }

    if (releaseVersion && isNewVersion(releaseVersion)) {
        dispatch(serviceInfoActions.serviceInfoDialogOpenedAtChapter(chapters.whatsNew));
    }

    storage.save('releaseVersion', config.releaseVersion);
};

export const useServiceInfoDialogOnStart = (showMap: boolean) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        triggerServiceInfoDialog(dispatch, window.location.hash);
    }, []);
};
