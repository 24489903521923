import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { DEFAULT_ROUTE, routes } from './routes';

const isUnknownRoute = (routeNames: string[], pathname: string): boolean =>
    !routeNames.some(route => pathname.startsWith(route));

const DefaultRedirect = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (isUnknownRoute(routes, location.pathname)) {
            console.log('Unknown route, redirect to default route...');
            navigate(DEFAULT_ROUTE);
        }
    }, [location]);

    return null;
};

export default DefaultRedirect;
