import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import reduceReducers from 'reduce-reducers';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import omit from 'lodash/fp/omit';
import pick from 'lodash/fp/pick';
import omitBy from 'lodash/fp/omitBy';
import isNil from 'lodash/fp/isNil';
import isEmpty from 'lodash/fp/isEmpty';
import cloneDeep from 'lodash/fp/cloneDeep';
import isArray from 'lodash/fp/isArray';
import mergeWith from 'lodash/fp/mergeWith';
import set from 'lodash/fp/set';
import isEqual from 'lodash/fp/isEqual';
import head from 'lodash/fp/head';

import mapReducer, {
    defaultMapState,
    getCorrespondingSidebarTabContext,
    getMapContextDefaultSettings,
    getMapContextHistorySettings,
    showClusterChanged,
    workshopPoisToggled,
    customerPoisToggled,
    simplePayPoisToggled,
    geofencesToggled,
    chargingStationsToggled,
    type MapContextType,
    mapViewChanged,
    currentContextChanged,
    assetLockOnMapToggled,
    mapLayersChanged,
    appActions,
} from '../../map/mapSlice';
import { dataReducer, defaultDataState } from './dataReducer';
import { defaultTableState, tableReducer } from '../../table/tableReducer';
import { ASSET_TAB_HISTORY, defaultSidebarState, sidebarReducer } from '../../sidebar/sidebarReducer';
import treeReducer, {
    assetGroupsToggled,
    defaultTreeState,
    driverGroupsToggled,
    emptyGroupsToggled,
    fuelTypeToggled,
} from '../../tree/treeSlice';
import { defaultOnboardingState, onboardingReducer } from './onboardingReducer';
import { serviceInfoReducer, defaultServiceInfoState } from '../../serviceInfo/serviceInfoReducer';
import shareLinkReducer, { defaultShareLinkState } from '../../dialogs/shareLinkDialog/shareLinkSlice';
import widgetsReducer from '../../widgets/widgetsSlice';
import assetAdminReducer, { defaultAssetAdminState } from '../../dialogs/assetAdminDialog/assetAdminSlice';
import { poiGeofenceReducer } from '../../dialogs/poiGeofenceDialog/poiGeofenceReducer';

import { storage } from '../../../configuration/setup/storage';
import { actions } from '../../../services/actions';
import { sidebarActions } from '../../sidebar/sidebarActions';
import { summaryActions } from '../../summary/summaryActions';
import { serviceInfoActions } from '../../serviceInfo/serviceInfoActions';
import { tableActions } from '../../table/tableActions';
import { mapTypeChanged } from '../../map/mapSlice';
import { parseRoute } from '../../../routes/routeIntents';
import { gaPush, TRACKING_CATEGORIES } from '../../../configuration/setup/googleTagManager';
import type { RootState } from '../../../configuration/setup/store';
import type { Asset } from '../../../services/types';
import { compact, uniq } from 'lodash';

export const defaultAppState = {};

const storeMap: RootState = {
    data: defaultDataState,
    onboarding: defaultOnboardingState,
    serviceInfo: defaultServiceInfoState,
    table: defaultTableState,
    sidebar: defaultSidebarState,
    tree: defaultTreeState,
    map: defaultMapState,
    shareLink: defaultShareLinkState,
    assetAdmin: defaultAssetAdminState,
};

type UrlState = {
    [key: string]: unknown;
    search: string;
};

const prepareState = (parsedUrlState: UrlState, state: RootState) => {
    const cleanedUrlState = flow(omitBy(isNil), omit(['']))(parsedUrlState);

    // Special handling for empty values which are intentionally set in order to remove those values again
    const urlState: UrlState = {
        ...cleanedUrlState,
        search: parsedUrlState.search,
    };

    // Map the list of props derived from the URL to the actual location in the redux store
    const urlStateKeys = Object.keys(urlState);
    const storeMapKeys = Object.keys(storeMap);
    const mappedState = cloneDeep<RootState>(state);

    urlStateKeys.forEach(key => {
        storeMapKeys.forEach(category => {
            if (Object.keys(storeMap[category]).includes(key)) {
                mappedState[category] = {
                    ...mappedState[category],
                    [key]: urlState[key],
                };
            }
        });
    });

    // Define Map context
    if (urlState.activeAssetId && urlState.tabId === ASSET_TAB_HISTORY) {
        mappedState.map = { ...mappedState.map, ...getMapContextHistorySettings() };
    } else {
        mappedState.map = { ...mappedState.map, ...getMapContextDefaultSettings() };
    }

    return {
        ...mappedState,
    };
};

const STORAGE_KEY = 'ui.state';
const uiStateProps = pick([
    'table',
    'sidebar',
    'tree.treeCategory',
    'tree.isTreeOpen',
    'tree.showEmptyGroups',
    'tree.showAssetGroups',
    'tree.showDriverGroups',
    'tree.showFuelType',
    'tree.globalSearchValue',
    'map.zoom',
    'map.center',
    'map.currentContext',
    'map.lockOnAsset',
    'map.mapType',
    'map.mapLayers',
    'map.showCluster',
    'map.showWorkshopPois',
    'map.showCustomerPois',
    'map.showSimplePayPois',
    'map.showGeofences',
    'map.showChargingStations',
    'serviceInfo',
]);

// Sync current state to localStorage - pick only wanted data
const synchronizeStorage = (state: RootState) => {
    const saveState = { ...uiStateProps(state) };
    storage.save(STORAGE_KEY, saveState);
    return state;
};

// When selecting an Asset, check for the current sidebar tab to set the respective map context
// Note: its has been defined here as we need to access the "tabId" inside the "sidebar" scope as well as
// the "currentContext" inside the "map" scope.
const updateMapContextOnAssetChange = (state: RootState) => {
    const currentContext = get('map.currentContext')(state);
    const updatedContext = getCorrespondingSidebarTabContext(get('sidebar.tabId')(state));
    const updateMapContext = (currentState: RootState, mapContext: MapContextType) =>
        set('map.currentContext', mapContext)({ ...currentState });
    return isEqual(currentContext, updatedContext) ? state : updateMapContext(state, updatedContext);
};

const mergeCustomizer = (targetValue: unknown, srcValue: unknown) => {
    if (isArray(targetValue) && isArray(srcValue)) {
        return srcValue;
    }
};

const assetsHaveDifferentFuelTypes = (assets: Asset[]): boolean => {
    if (!assets || isEmpty(assets)) {
        return false;
    }
    const uniqueFuelTypes = uniq(compact(assets.map(a => a.fuelType)));
    return uniqueFuelTypes.length > 1;
};

export const locationReducer = handleActions(
    {
        // We listen for route changes and dispatch the "routeChanged" action to derive the new state from the URL.
        // To update the various parts of the state for various slices, we use this reducer to access every state part.

        // Note: make sure there is a default value defined for each prop, otherwise the value will not be set
        // due to some caveats in reduce-reducer
        [`${actions.routeChanged}`]: (state: RootState, action) => {
            if (isEmpty(action.payload)) {
                return state;
            }

            if (action.payload.isFirstRendering) {
                const storageState = storage.load(STORAGE_KEY);

                if (isEmpty(get('payload.location.search')(action))) {
                    gaPush({
                        category: TRACKING_CATEGORIES.NAVIGATION,
                        action: 'Browser Navigation',
                        label: 'Restored Ui from Localstorage',
                    });

                    // Merge the storageState from localStorage into current redux state and restore saved values
                    return mergeWith(mergeCustomizer, state, storageState);
                }

                gaPush({
                    category: TRACKING_CATEGORIES.NAVIGATION,
                    action: 'Browser Navigation',
                    label: 'Called Deeplink',
                });

                const parsedUrlState = parseRoute(action.payload);

                // Merge default redux state with state loaded from local storage in order to restore those values that are not part
                // of the local storage state.
                const stateWithLocalStorageState = mergeWith(mergeCustomizer, state, storageState);

                // Apply URL state over restored state from local storage to give the URL state precedence.
                // Normal use case is when navigating back from asset history with selected asset in url
                // but no other state is defined in the url. This will avoid using the default values but rather restore
                // previous stored values
                return prepareState(parsedUrlState, stateWithLocalStorageState);
            }

            // Initial update of the localStorage state.
            // Note, it should not be updated from the URL but when the actual state changes.
            // This allows to omit default values from the URL but still updating the localStorage. See at bottom.
            synchronizeStorage(state);

            if (action.payload.action !== 'POP') {
                return state;
            }

            gaPush({
                category: TRACKING_CATEGORIES.NAVIGATION,
                action: 'Browser Navigation',
                label: 'Back/Forward Button',
            });
            const parsedUrlState = parseRoute(action.payload);
            return prepareState(parsedUrlState, state);
        },
        [`${sidebarActions.assetSelectedFromClusterSidebar}`]: (state: RootState) =>
            updateMapContextOnAssetChange(state),

        [`${actions.activeAssetChanged}`]: (state: RootState, action) =>
            action.payload ? updateMapContextOnAssetChange(state) : state,

        [`${actions.assetsChanged}`]: (state: RootState, action) => {
            if (action.payload && isNil(state.tree.showFuelType)) {
                state.tree.showFuelType = assetsHaveDifferentFuelTypes(action.payload);
            }
            return state;
        },

        [`${actions.activeAssetChanged}`]: (state: RootState, action) => {
            const { data, tree } = state;
            const assetId = action.payload;
            if (!assetId) {
                return state;
            }

            // FIXME:
            // when a vehicle is selected on the map and there is no asset tree filter set,
            // select the asset in the tree as well

            // Note: this resets the selected driver if the new active asset is associated.
            // This will change in the future as the driver will have it's own map context
            const selectedDriverId = head(tree.selectedDriverIds);
            if (selectedDriverId) {
                const activeAsset = data.rawData.find((asset: Asset) => asset.vehicleId === assetId);
                const isDriverAssociatedToAsset = activeAsset.driverId === selectedDriverId;
                if (!isDriverAssociatedToAsset) {
                    return set('tree.selectedDriverIds', defaultTreeState.selectedDriverIds)({ ...state });
                }
            }
            return state;
        },

        // The localStorage state should not be updated from the URL but when the actual state changes.
        // This allows to omit default values from the URL but still updating the localStorage
        [`${mapTypeChanged}`]: (state: RootState) => synchronizeStorage(state),
        [`${mapLayersChanged}`]: (state: RootState) => synchronizeStorage(state),
        [`${mapViewChanged}`]: (state: RootState) => synchronizeStorage(state),
        [`${currentContextChanged}`]: (state: RootState) => synchronizeStorage(state),
        [`${assetLockOnMapToggled}`]: (state: RootState) => synchronizeStorage(state),

        [`${showClusterChanged}`]: (state: RootState) => synchronizeStorage(state),
        [`${workshopPoisToggled}`]: (state: RootState) => synchronizeStorage(state),
        [`${customerPoisToggled}`]: (state: RootState) => synchronizeStorage(state),
        [`${simplePayPoisToggled}`]: (state: RootState) => synchronizeStorage(state),
        [`${geofencesToggled}`]: (state: RootState) => synchronizeStorage(state),
        [`${chargingStationsToggled}`]: (state: RootState) => synchronizeStorage(state),

        [`${emptyGroupsToggled}`]: (state: RootState) => synchronizeStorage(state),
        [`${assetGroupsToggled}`]: (state: RootState) => synchronizeStorage(state),
        [`${driverGroupsToggled}`]: (state: RootState) => synchronizeStorage(state),
        [`${fuelTypeToggled}`]: (state: RootState) => synchronizeStorage(state),

        [`${appActions.treeCategoryChanged}`]: (state: RootState) => synchronizeStorage(state),
        [`${appActions.globalSearchValueChanged}`]: (state: RootState) => synchronizeStorage(state),
        [`${appActions.treeToggled}`]: (state: RootState) => synchronizeStorage(state),

        [`${sidebarActions.sidebarTabSelectionChanged}`]: (state: RootState) => synchronizeStorage(state),

        [`${serviceInfoActions.serviceInfoDialogClosed}`]: (state: RootState) => synchronizeStorage(state),
        [`${serviceInfoActions.serviceInfoDialogOpenedAtChapter}`]: (state: RootState) => synchronizeStorage(state),

        [`${tableActions.tableSearchChanged}`]: (state: RootState) => synchronizeStorage(state),
        [`${tableActions.sortingPropsChanged}`]: (state: RootState) => synchronizeStorage(state),
        [`${tableActions.tableViewTypeChanged}`]: (state: RootState) => synchronizeStorage(state),
        [`${tableActions.tableSettingsDialogToggled}`]: (state: RootState) => synchronizeStorage(state),
        [`${tableActions.tableColumnsChanged}`]: (state: RootState) => synchronizeStorage(state),

        [`${summaryActions.activityFilterChanged}`]: (state: RootState) => synchronizeStorage(state),
        [`${summaryActions.assetTypeFilterChanged}`]: (state: RootState) => synchronizeStorage(state),
        [`${summaryActions.notificationFilterChanged}`]: (state: RootState) => synchronizeStorage(state),
    },
    storeMap
);

export const combinedReducer = combineReducers({
    data: dataReducer,
    onboarding: onboardingReducer,
    serviceInfo: serviceInfoReducer,
    table: tableReducer,
    sidebar: sidebarReducer,
    tree: treeReducer,
    map: mapReducer,
    poiGeofence: poiGeofenceReducer,
    shareLink: shareLinkReducer,
    assetAdmin: assetAdminReducer,
    widgets: widgetsReducer,
});

export const appReducer = reduceReducers(combinedReducer, locationReducer);
