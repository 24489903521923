import Dialog from '@rio-cloud/rio-uikit/Dialog';

import { FeatureBaseWrapper } from './FeatureBaseWrapper';
import { accessToken } from '../../configuration/tokenHandling/accessToken';

type FeatureBaseDialogProps = {
    show: boolean;
    onClose: () => void;
};

export const FeatureBaseDialog = (props: FeatureBaseDialogProps) => {
    const { show, onClose } = props;

    return (
        <Dialog
            show={show}
            title=' '
            body={
                <FeatureBaseWrapper
                    action='embed'
                    rioAccessTokenResolver={async () => accessToken.getAccessToken()}
                    hideLogo={true}
                    hideMenu={true}
                />
            }
            bodyClassName='padding-0 overflow-hidden'
            bsSize={Dialog.SIZE_FULL_SCREEN}
            onClose={() => onClose()}
            showCloseButton
        />
    );
};
