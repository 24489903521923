import Notification from '@rio-cloud/rio-uikit/Notification';
import head from 'lodash/fp/head';
import type { IntlShape } from 'react-intl';

export const fastCloneDeep = (it: unknown) => JSON.parse(JSON.stringify(it));

export const isLocalhost = () => window?.location.hostname === 'localhost' || window?.location.hostname === '127.0.0.1';

export const getValueIfTrue = (bool: boolean, value: unknown) => (bool ? value : undefined);

export const copyToClipboard = (text: string, intl: IntlShape) => {
    const input = document.createElement('input');
    input.style.position = 'fixed';
    input.style.opacity = '0';
    input.value = text;
    document.body.appendChild(input);
    input.select();
    document.execCommand('Copy');
    document.body.removeChild(input);

    if (intl) {
        Notification.info(intl.formatMessage({ id: 'intl-msg:livemonitor.notification.copyToClipboard' }), text);
    }
};

// This check relys on proper class names on the html element to check for certain browser
export const canUseWebP = () => {
    const classList = [...(document.documentElement.classList || [])];

    if (classList.includes('ua-ie') || classList.includes('ua-ie-11')) {
        return false;
    }

    if (classList.includes('ua-safari')) {
        const uaSafariClasses = new Set();
        classList.map(
            (item: string) => item.startsWith('ua-safari-') && uaSafariClasses.add(item.replace('ua-safari-', ''))
        );
        const isOldSafari = [...uaSafariClasses].some((item: string) => Number(head(item.split('-')) || 0) < 14);
        return isOldSafari;
    }

    return true;
};
