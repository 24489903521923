import { useEffect } from 'react';

const TIMEOUT_IN_MS = 43_200_000;

// Reload Livemonitor after 12 hours
export const useTimedPageReload = (timeout = TIMEOUT_IN_MS) => {
    const reloadLivemonitor = () => {
        setTimeout(() => {
            window.location.reload();
        }, timeout);
    };

    useEffect(() => {
        window.addEventListener('resize', reloadLivemonitor, false);
        return () => window.removeEventListener('resize', reloadLivemonitor, true);
    });
};
