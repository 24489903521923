import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { UserProfile as Profile } from 'oidc-client-ts';

import type { RootState } from '../../configuration/setup/store';

import { accessToken } from './accessToken';
import { useAppSelector } from '../setup/hooks';

export const TENANT_RIO_EU_PROD = 'rio-eu.prod';
export const TENANT_RIO_EU_TEST = 'rio-eu.test';
export const TENANT_RIO_BRAZIL_PROD = 'rio-brazil.prod';
export const TENANT_RIO_SOUTHKOREA_PROD = 'rio-southkorea.prod';

export type AccessToken = string;

export type AccessTokenState = {
    accessToken: AccessToken | undefined;
    idToken: Profile | undefined;
};

const initialState: AccessTokenState = {
    accessToken: accessToken.getAccessToken(),
    idToken: undefined,
};

const tokenSlice = createSlice({
    name: 'tokenHandling',
    initialState,
    reducers: {
        accessTokenStored: (state, action: PayloadAction<AccessToken>) => {
            state.accessToken = action.payload;
        },
        idTokenStored: (state, action: PayloadAction<Profile>) => {
            state.idToken = action.payload;
        },
    },
});

export const { accessTokenStored, idTokenStored } = tokenSlice.actions;

export const getAccessToken = (state: RootState): AccessToken =>
    state.tokenHandling.accessToken ?? 'NO_ACCESS_TOKEN_AVAILABLE';
export const getIdToken = (state: RootState): Profile | undefined => state.tokenHandling.idToken;
export const getTenant = (state: RootState): string => state.tokenHandling.idToken?.tenant;

export const useIdToken = () => useAppSelector(getIdToken);
export const useTenant = () => useAppSelector(getTenant);
export const useAccessToken = () => useAppSelector(getAccessToken);

export default tokenSlice.reducer;
