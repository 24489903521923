import { handleActions } from 'redux-actions';
import mapValues from 'lodash/fp/mapValues';

import { serviceInfoActions } from '../../serviceInfo/serviceInfoActions';
import { storage } from '../../../configuration/setup/storage';

export const defaultOnboardingState = {
    showTableSettingsTip: true,
    showQuickFilter: true,
    showTree: true,
    showShareLinkTip: true,
};

export const onboardingReducer = handleActions(
    {
        [`${serviceInfoActions.onboardingToggled}`]: (state, action) => {
            const onboarding = {
                ...state,
                [action.payload.tip]: action.payload.toggle,
            };

            storage.save('onboarding', onboarding);
            return {
                ...state,
                ...onboarding,
            };
        },
        [`${serviceInfoActions.onboardingResetted}`]: (state, action) => {
            const onboarding = action.payload ? defaultOnboardingState : mapValues(() => false)(defaultOnboardingState);

            storage.save('onboarding', onboarding);
            return {
                ...onboarding,
            };
        },
    },
    storage.load('onboarding') || defaultOnboardingState
);
