import type { Dispatch } from 'redux';

import { fetchData } from '../configuration/setup/fetch';
import { actions } from './actions';
import { config } from '../config';
import { getAccessToken } from '../configuration/tokenHandling/tokenSlice';
import type { RootState } from '../configuration/setup/store';

export const fetchPoi = async (dispatch: Dispatch, getState: () => RootState) => {
    try {
        const url = `${config.backend.LIVEMONITOR_SERVICE}/pois`;
        const result = await fetchData(url, getAccessToken(getState()));

        const pois = {
            workshopPois: result.workshop_pois || [],
            customerPois: result.customer_pois || [],
        };

        dispatch(actions.fetchPoisSuccess(pois));
    } catch (error: unknown) {
        dispatch(actions.fetchPoisFailed(error as Error));
    }
};
