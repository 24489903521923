import { createAction } from 'redux-actions';

const actions = {
    geofenceDialogToggled: createAction('monitor/poiGeofence/GEOFENCE_DIALOG_TOGGLED'),
    poiDialogToggled: createAction('monitor/poiGeofence/POI_DIALOG_TOGGLED'),
    positionDefined: createAction('monitor/poiGeofence/POSITION_DEFINED'),
    setPoiId: createAction('monitor/poiGeofence/SET_POI_ID'),
    setGeofenceId: createAction('monitor/poiGeofence/SET_GEOFENCE_ID'),
};

export const poiGeofenceActions = actions;
