import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect, batch } from 'react-redux';
import PropTypes from 'prop-types';
import Dialog from '@rio-cloud/rio-uikit/Dialog';

import { isPoiDialogShown, isGeofenceDialogShown, getPoiId, getGeofenceId } from './poiGeofenceSelectors';
import { poiGeofenceActions } from './poiGeofenceActions';
import { customerPoisToggled } from '../../map/mapSlice';
import { fetchPoi } from '../../../services/fetchPoi';
import { fetchGeofences } from '../../../services/fetchGeofences';
import { remoteActions } from '../../widgets/remoteActions';
import { sendMessage } from '../../widgets/MessageHandler';
import { isLocalhost } from '../../../utils/utils';
import { getCenter } from '../../map/mapSelectors';

const LOCAL_GEOFENCE_ADMIN_URL = 'http://localhost:3040';
const PROD_GEOFENCE_ADMIN_URL = 'https://admin.geofencing.rio.cloud';

const getPage = showPoiDialog => (showPoiDialog ? 'pois' : 'geofences');
const getParams = position => (position ? `?lat=${position.lat}&lng=${position.lng}` : '');

export const buildGeofenceUrl = ({ showPoiDialog, poiId, geofenceId, position }) => {
    const id = poiId ?? geofenceId;
    const page = getPage(showPoiDialog);
    const params = getParams(position);

    const url = isLocalhost() ? LOCAL_GEOFENCE_ADMIN_URL : PROD_GEOFENCE_ADMIN_URL;

    if (id) {
        return `${url}/#${page}/edit/${id}`;
    }

    return `${url}/#${page}/create${params}`;
};

const DialogBody = props => {
    const url = buildGeofenceUrl(props);
    return (
        <div className={'iframe-wrapper'}>
            {/* biome-ignore lint/a11y/useIframeTitle: TODO Provide title */}
            <iframe className={'unstyled bg-lighter full-window no-application-header'} src={url} />
        </div>
    );
};

const PoiGeofenceDialog = React.memo(props => {
    const { showPoiDialog, showGeofenceDialog, onHide, ...remainingProps } = props;

    return (
        <Dialog
            show={showPoiDialog || showGeofenceDialog}
            title={<FormattedMessage id={'intl-msg:livemonitor.sublink.poisAndGeofences'} />}
            bodyClassName={'padding-0 display-flex'}
            body={<DialogBody showPoiDialog={showPoiDialog} {...remainingProps} />}
            onClose={onHide}
            showCloseButton={true}
            bsSize={Dialog.SIZE_FULL_SCREEN}
        />
    );
});

PoiGeofenceDialog.propTypes = {
    showPoiDialog: PropTypes.bool,
    showGeofenceDialog: PropTypes.bool,
    onHide: PropTypes.func,
    position: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
    }),
    poiId: PropTypes.string,
    geofenceId: PropTypes.string,
};

const mapStateToProps = state => ({
    showPoiDialog: isPoiDialogShown(state),
    showGeofenceDialog: isGeofenceDialogShown(state),
    position: getCenter(state),
    poiId: getPoiId(state),
    geofenceId: getGeofenceId(state),
});

const mapDispatchToProps = dispatch => ({
    onHide: () =>
        batch(() => {
            dispatch(poiGeofenceActions.geofenceDialogToggled(false));
            dispatch(poiGeofenceActions.poiDialogToggled(false));
            dispatch(customerPoisToggled(true));
            dispatch(fetchPoi);
            dispatch(fetchGeofences);
            sendMessage(remoteActions.refreshPois());
            sendMessage(remoteActions.refreshGeofences());
        }),
});

export const PoiGeofenceDialogContainer = connect(mapStateToProps, mapDispatchToProps)(PoiGeofenceDialog);

export default PoiGeofenceDialogContainer;
