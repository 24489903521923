import { createAction } from 'redux-actions';

const actions = {
    tableSearchChanged: createAction('monitor/table/SEARCH_CHANGED'),
    tableColumnsChanged: createAction('monitor/table/COLUMNS_CHANGED'),
    tableSettingsDialogToggled: createAction('monitor/table/SETTINGS_TOGGLED'),
    sortingPropsChanged: createAction('monitor/table/SORTING_PROPS_CHANGED'),
    chunkCounterChanged: createAction('monitor/table/CHUNK_COUNTER_CHANGED'),
    tableViewTypeChanged: createAction('monitor/table/VIEW_TYPE_CHANGED'),
};

export const tableActions = actions;
