import React, { useState } from 'react';

import { useShowFeedbackButtonAnimation } from './useShowFeedbackButtonAnimation';
import { useHideOnSmallScreen } from './useHideOnSmallScreen';
import { AnimatedFeedbackButton } from './AnimatedFeedbackButton';
import { StaticFeedbackButton } from './StaticFeedbackButton';
import { FeatureBaseDialog } from '../FeatureBaseDialog';
import { gaPush, TRACKING_CATEGORIES } from '../../../configuration/setup/googleTagManager';
import { useUserAccount } from '../../../configuration/login/loginSlice';
import { DEMO_FLEET_ACCOUNT_ID } from '../../../data/dataDefinition';

export const FeedbackButton = React.memo(() => {
    const isAnimationVisible = useShowFeedbackButtonAnimation();
    const isVisible = useHideOnSmallScreen();

    const [showDialog, setShowDialog] = useState(false);

    const userAccount = useUserAccount();

    // Hide this for the demo fleet account id
    const isDemoAccount = userAccount === DEMO_FLEET_ACCOUNT_ID;

    if (!isVisible || isDemoAccount) {
        return null;
    }

    const handleShowDialog = () => {
        setShowDialog(true);

        gaPush({
            action: 'Feedback button clicked',
            label: 'Clicked on Feedback button to open feature voting dialog',
            category: TRACKING_CATEGORIES.TREE,
        });
    };

    const handleCloseDialog = () => {
        setShowDialog(false);

        gaPush({
            action: 'Close FeatureVoting button clicked',
            label: 'Clicked on dialog close button to close feature voting dialog',
            category: TRACKING_CATEGORIES.TREE,
        });
    };

    const buttonComponent = isAnimationVisible ? (
        <AnimatedFeedbackButton onClick={handleShowDialog} />
    ) : (
        <StaticFeedbackButton onClick={handleShowDialog} />
    );

    return (
        <>
            {buttonComponent}
            <FeatureBaseDialog show={showDialog} onClose={handleCloseDialog} />
        </>
    );
});
