import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../../../configuration/setup/store';
import { useAppSelector } from '../../../configuration/setup/hooks';

export type SliceState = {
    showShareLinkDialog: boolean;
};

export const defaultShareLinkState: SliceState = {
    showShareLinkDialog: false,
};

const shareLinkSlice = createSlice({
    name: 'shareLink',
    initialState: defaultShareLinkState,
    reducers: {
        shareLinkDialogToggled: (state, action: PayloadAction<boolean>) => {
            state.showShareLinkDialog = action.payload;
        },
    },
});

export const isShareLinkDialogShown = (state: RootState) => state.app.shareLink.showShareLinkDialog;

export const useIsShareLinkDialogShown = () => useAppSelector(isShareLinkDialogShown);

export const { shareLinkDialogToggled } = shareLinkSlice.actions;

export default shareLinkSlice.reducer;
