import { handleActions } from 'redux-actions';
import { poiGeofenceActions } from './poiGeofenceActions';

export const defaultPoiGeofenceState = {
    showPoiDialog: false,
    showGeofenceDialog: false,
    position: {},
    poiId: undefined,
    geofenceId: undefined,
};

export const poiGeofenceReducer = handleActions(
    {
        [poiGeofenceActions.geofenceDialogToggled]: (state, action) => {
            if (action.payload) {
                return { ...state, showGeofenceDialog: action.payload, showPoiDialog: false };
            }
            return { ...defaultPoiGeofenceState };
        },
        [poiGeofenceActions.poiDialogToggled]: (state, action) => {
            if (action.payload) {
                return { ...state, showGeofenceDialog: false, showPoiDialog: action.payload };
            }
            return { ...defaultPoiGeofenceState };
        },
        [poiGeofenceActions.positionDefined]: (state, action) => ({ ...state, position: action.payload }),
        [poiGeofenceActions.setPoiId]: (state, action) => ({ ...state, poiId: action.payload }),
        [poiGeofenceActions.setGeofenceId]: (state, action) => ({ ...state, geofenceId: action.payload }),
    },
    defaultPoiGeofenceState
);
