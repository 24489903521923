import type { IntlShape } from 'react-intl';
import type { Dispatch } from 'redux';

import { actions } from './actions';
import { config } from '../config';
import { fetchGroups } from './fetchGroups';
import { fetchAssetsAndDriversWithStates } from './fetchStates';
import { fetchPoi } from './fetchPoi';
import { fetchGeofences } from './fetchGeofences';
import type { RootState } from '../configuration/setup/store';

export const FALLBACK_DATA_POLL_INTERVAL = 400;

const startPolling = (() => {
    let timeout: NodeJS.Timeout;

    return (intl: IntlShape) => (dispatch: Dispatch, getState: () => RootState) => {
        const poll = () => {
            const pollInterval = config.backend.POLL_INTERVAL;

            dispatch(fetchAssetsAndDriversWithStates(intl));
            timeout = setTimeout(poll, pollInterval || FALLBACK_DATA_POLL_INTERVAL);
        };

        clearTimeout(timeout);
        poll();
    };
})();

export const fetchInitialData = (intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(actions.fetchInitialDataRequested());
    dispatch(fetchGroups(intl));
    dispatch(startPolling(intl));
    dispatch(actions.fetchInitialDataSuccess());
    dispatch(fetchPoi);
    dispatch(fetchGeofences);
};
