import React, { lazy, Suspense } from 'react';

import { MainLoadingIndicator } from '../components/MainLoadingIndicator';
const MapContainer = lazy(() => import('../features/map/MapContainer'));
const TableContainer = lazy(() => import('../features/table/Table'));

type MainPagesProps = {
    isMap: boolean;
};
// Initialize and render the map container only once and when navigating to list view just hide the map
// as it's expensive to initialize and add the map again to the DOM
export const MainPages = ({ isMap }: MainPagesProps) => {
    const showMap = isMap;
    const isOffscreen = !showMap;

    return (
        <React.Fragment>
            {!showMap && (
                <Suspense fallback={<MainLoadingIndicator />}>
                    <TableContainer />
                </Suspense>
            )}
            <Suspense fallback={<MainLoadingIndicator />}>
                <MapContainer className={`${isOffscreen ? 'position-offscreen' : ''}`} />
            </Suspense>
        </React.Fragment>
    );
};
