import { useState } from 'react';
import { toDate } from 'date-fns/toDate';
import { parseISO } from 'date-fns/parseISO';
import { isToday } from 'date-fns/isToday';
import useEffectOnce from '@rio-cloud/rio-uikit/useEffectOnce';
import useLocalStorage from '@rio-cloud/rio-uikit/useLocalStorage';

const NUMBER_OF_DAYS_TO_SHOW = 7;
const MILLISECONDS_PER_DAY = 24 * 60 * 60 * 1000;

export const useShowFeedbackButtonAnimation = (): boolean => {
    const [isAnimationVisible, setIsAnimationVisible] = useState(false);

    const [firstShownDate, setFirstShownDate] = useLocalStorage<string | undefined>(
        'livemonitor.featureVoting.feedbackButton.firstShownDate',
        undefined
    );

    const [lastShownDate, setLastShownDate] = useLocalStorage<string | undefined>(
        'livemonitor.featureVoting.feedbackButton.lastShownDate',
        undefined
    );

    const shouldShowAnimation = (isoDateString: string | undefined): boolean => {
        if (!isoDateString) {
            return true;
        }
        const lastShown = Date.parse(isoDateString);
        return lastShown + NUMBER_OF_DAYS_TO_SHOW * MILLISECONDS_PER_DAY >= Date.now();
    };

    useEffectOnce(() => {
        if (!firstShownDate) {
            setFirstShownDate(toDate(Date.now()).toISOString());
        }

        const shownToday = lastShownDate ? isToday(parseISO(lastShownDate)) : false;

        const showAnimation = shouldShowAnimation(firstShownDate) && !shownToday;

        if (showAnimation) {
            setLastShownDate(toDate(Date.now()).toISOString());
            setIsAnimationVisible(true);
        }
    });
    return isAnimationVisible;
};
