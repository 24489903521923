import { useAppSelector } from '../../configuration/setup/hooks';
import {
    getActivityFilter,
    getAssetFilter,
    getColumns,
    getNotificationFilter,
    getSearchValue,
    getSortBy,
    getSortDir,
} from './tableSelectors';

export const useActivityFilter = () => useAppSelector(getActivityFilter);
export const useAssetFilter = () => useAppSelector(getAssetFilter);
export const useNotificationFilter = () => useAppSelector(getNotificationFilter);

export const useSearchValue = () => useAppSelector(getSearchValue);
export const useSortBy = () => useAppSelector(getSortBy);
export const useSortDir = () => useAppSelector(getSortDir);
export const useColumns = () => useAppSelector(getColumns);
