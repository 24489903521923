import { createHashRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom';

import { ErrorBoundary } from '../components/ErrorBoundary';
import { AppContainer } from '../layout/AppContainer';
import { AppLayout } from '../layout/AppLayout';
import { LIST_PATH, MAP_PATH } from './routes';

export const router = createHashRouter(
    createRoutesFromElements(
        <Route path='/' element={<AppContainer />}>
            <Route path={MAP_PATH} errorElement={<ErrorBoundary />} element={<AppLayout />}>
                <Route path=':id' element={<AppLayout />} />
            </Route>
            <Route path={LIST_PATH} errorElement={<ErrorBoundary />} element={<AppLayout />}>
                <Route path=':id' element={<AppLayout />} />
            </Route>
            {/**
             * Custom handler to ensure the redirect after login is working. This deviates
             * from the common route handling as it is defined in the Frontend template.
             * To be investigated if this can be replaced again.
             */}
            <Route path='*' element={<Navigate to={MAP_PATH} replace />} />
        </Route>
    )
);
