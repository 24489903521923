import { handleActions } from 'redux-actions';

import { sidebarActions } from './sidebarActions';
import { actions } from '../../services/actions';

export const ASSET_TAB_SUMMARY = 'SUMMARY';
export const ASSET_TAB_HISTORY = 'HISTORY';
export const ASSET_TAB_ACTIVITY = 'ACTIVITY';
export const ASSET_TAB_REMOTE_DOWNLOAD = 'REMOTE_DOWNLOAD';
export const ASSET_TAB_NOTIFICATIONS = 'NOTIFICATIONS';
export const ASSET_TAB_UNIT = 'UNIT';
export const ASSET_TAB_RANGE = 'RANGE';

export const assetTabs = {
    1: ASSET_TAB_SUMMARY,
    2: ASSET_TAB_HISTORY,
    3: ASSET_TAB_ACTIVITY,
    5: ASSET_TAB_REMOTE_DOWNLOAD,
    6: ASSET_TAB_NOTIFICATIONS,
    7: ASSET_TAB_UNIT,
    8: ASSET_TAB_RANGE,
};

export const getAssetTabById = id => assetTabs[id];
export const getAssetTabId = (type, set) => Object.keys(set).find(key => set[key] === type);

export const defaultSidebarState = {
    tabId: ASSET_TAB_SUMMARY,
};

export const sidebarReducer = handleActions(
    {
        [sidebarActions.sidebarTabSelectionChanged]: (state, action) => ({ ...state, tabId: action.payload }),

        // Reset tab to summary since no asset is selected
        [actions.activeAssetChanged]: (state, action) => ({
            ...state,
            tabId: action.payload ? state.tabId : ASSET_TAB_SUMMARY,
        }),
    },
    defaultSidebarState
);
