import type { IntlShape } from 'react-intl';
import type { Dispatch } from 'redux';
import isEmpty from 'lodash/fp/isEmpty';

import { fetchData } from '../configuration/setup/fetch';
import { actions } from './actions';
import { config } from '../config';
import { getAccessToken } from '../configuration/tokenHandling/tokenSlice';
import type { RootState } from '../configuration/setup/store';
import type { Group } from './types';

export const UNGROUPED_ITEMS_GROUP_ID = 'unassigned';

export const getUngroupedGroup = (intl: IntlShape) => ({
    id: UNGROUPED_ITEMS_GROUP_ID,
    name: intl.formatMessage({ id: 'intl-msg:asset-tree.groups.ungrouped' }),
    position: 'last',
});

export const fetchGroups = (intl: IntlShape) => async (dispatch: Dispatch, getState: () => RootState) => {
    try {
        dispatch(actions.fetchGroupsRequested());

        const groupsUrl = `${config.backend.LIVEMONITOR_SERVICE}/states/groups`;
        const groupList = await fetchData(groupsUrl, getAccessToken(getState()));
        const groups = groupList.items;

        // dont add an unassigned group if there are no other groups -> flat list of vehicles
        const groupsWithVirtualGroups: Group[] = !groups || isEmpty(groups) ? [] : [...groups, getUngroupedGroup(intl)];

        dispatch(actions.groupsChanged(groupsWithVirtualGroups));
        dispatch(actions.fetchGroupsSuccess());
    } catch (error: unknown) {
        dispatch(actions.fetchGroupsFailed(error as Error));
    }
};
