import React from 'react';
import PropTypes from 'prop-types';

import { ClusterSidebarAsset } from './ClusterSidebarAsset';

export const ClusterSidebarContent = React.memo(({ assets = [], onSelectAsset = () => {} }) => {
    return (
        <div className={'ClusterSidebarContent padding-x-20 padding-top-5'}>
            {assets.map(asset => {
                const assetId = asset.vehicleId;
                return <ClusterSidebarAsset key={assetId} asset={asset} onClick={() => onSelectAsset(assetId)} />;
            })}
        </div>
    );
});

ClusterSidebarContent.propTypes = {
    assets: PropTypes.arrayOf(PropTypes.object),
    onSelectAsset: PropTypes.func,
};
