import { createSelector } from 'reselect';
import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';
import negate from 'lodash/fp/negate';
import compact from 'lodash/fp/compact';

import { ASSET_TYPE_DRIVER } from '../../data/dataDefinition';
import { tableConfig, TABLE_COLUMN_ACTIVITY } from './tableConfig';
import { getData, getTransformedData } from '../app/selectors';
import { sortItems, isMatchingValue } from './tableUtils';
import { NotificationStates } from '../../services/types';

const BASE = 'app';

export const getSearchValue = get(`${BASE}.table.search`);
export const getSortBy = get(`${BASE}.table.sortBy`);
export const getSortDir = get(`${BASE}.table.sortDir`);

export const getColumns = get(`${BASE}.table.columns`);
export const getColumnOrder = get(`${BASE}.table.columns.columnOrder`);
export const getHiddenColumns = get(`${BASE}.table.columns.hiddenColumns`);
export const getColumnsDetails = get(`${BASE}.table.columns.columnsDetails`);
export const getVisibleColumns = createSelector(
    getHiddenColumns,
    getColumnOrder,
    (hiddenColumns = [], columnOrder = []) => columnOrder.filter(name => !hiddenColumns.includes(name))
);

export const showTableSettingsDialog = get(`${BASE}.table.showTableSettings`);

export const getChunkCounter = get(`${BASE}.table.chunks`);

export const getViewType = get(`${BASE}.table.viewType`);

export const getActivityFilter = get(`${BASE}.table.activityFilter`);

export const getAssetFilter = get(`${BASE}.table.assetFilter`);

export const getNotificationFilter = get(`${BASE}.table.notificationFilter`);

// biome-ignore lint/style/useDefaultParameterLast: TODO Can we do something about it
export const searchItems = (items = [], columns = [], searchValue) => {
    if (!searchValue) {
        return items;
    }

    return items.filter(item =>
        columns.some(col => {
            const value = item[col] || '';
            if (col === TABLE_COLUMN_ACTIVITY) {
                return isMatchingValue(item.currentActivityDuration, searchValue);
            }
            return isMatchingValue(value, searchValue);
        })
    );
};

// biome-ignore lint/style/useDefaultParameterLast: TODO Can we do something about it
export const filterByActivity = (items = [], activityFilter) => {
    if (isEmpty(activityFilter)) {
        return items;
    }
    return items.filter(item => {
        const currentActivity = item[TABLE_COLUMN_ACTIVITY]?.toLowerCase();
        return currentActivity && activityFilter.includes(currentActivity);
    });
};

// biome-ignore lint/style/useDefaultParameterLast: TODO Can we do something about it
export const filterByAssetTypesOrDriver = (items = [], assetFilter) => {
    if (isEmpty(assetFilter)) {
        return items;
    }
    const filterDriver = assetFilter.includes(ASSET_TYPE_DRIVER) ? negate(isEmpty) : () => false;
    return items.filter(({ type, driver }) => assetFilter.includes(type) || filterDriver(driver));
};

// biome-ignore lint/style/useDefaultParameterLast: TODO Can we do something about it
export const filterByNotifications = (items = [], notificationFilter) => {
    if (isEmpty(notificationFilter)) {
        return items;
    }
    return items.filter(item => {
        const hasWarnings = item.numberWarnings > 0;
        const hasExceptions = item.numberExceptions > 0;

        if (
            notificationFilter.includes(NotificationStates.exception) &&
            notificationFilter.includes(NotificationStates.warning)
        ) {
            return hasExceptions && hasWarnings;
        }
        if (notificationFilter.includes(NotificationStates.exception)) {
            return hasExceptions;
        }
        if (notificationFilter.includes(NotificationStates.warning)) {
            return hasWarnings;
        }
        return false;
    });
};

const getSortedItems = (rawData, transformedData, sortBy, sortDir) => {
    if (!transformedData) {
        return transformedData;
    }

    // TODO: consider secondary sorting for:
    // - notifications (1. type, 2. amount (desc))
    // - activity (1. type, 2. duration (asc))

    // In case the sorting should be done on raw data, sort items first then
    // transform raw data so it can be rendered into the table. This means translating lables and formatting data.
    if (tableConfig.rawSortKeys.includes(sortBy)) {
        const sortedRawItems = sortItems(rawData, sortBy, sortDir);

        // sort transformed data according to sorted raw data
        return compact(
            sortedRawItems.map(rawItem =>
                transformedData.find(transformedItem => rawItem.vehicleId === transformedItem.dataKey)
            )
        );
    }

    const sortKey = tableConfig.sortKeyReplacement[sortBy] || sortBy;
    return sortItems(transformedData, sortKey, sortDir);
};

export const getFilteredTransformedData = createSelector(
    getData,
    getTransformedData,
    getActivityFilter,
    getAssetFilter,
    getNotificationFilter,
    getVisibleColumns,
    getSearchValue,
    getSortBy,
    getSortDir,
    (
        rawData,
        transformedData,
        activityFilter,
        assetFilter,
        notificationFilter,
        visibleColumns,
        searchValue,
        sortBy,
        sortDir
    ) => {
        let result = filterByActivity(transformedData, activityFilter);
        result = filterByAssetTypesOrDriver(result, assetFilter);
        result = filterByNotifications(result, notificationFilter);
        result = searchItems(result, visibleColumns, searchValue);
        result = getSortedItems(rawData, result, sortBy, sortDir);
        return result;
    }
);
