import type { IntlShape } from 'react-intl';
import type { Dispatch } from 'redux';
import keyBy from 'lodash/fp/keyBy';
import some from 'lodash/fp/some';
import omit from 'lodash/fp/omit';

import { actions } from './actions';
import { transformData } from '../data/dataDefinition';
import { config } from '../config';
import { mapAssetList } from './mapper/mapAssetList';
import { fetchData } from '../configuration/setup/fetch';
import { mapAssetLiveStates, mapDriverTimes } from './mapper/mapStates';
import { getAssets, getDrivers, getDrivingTimes } from '../features/app/selectors';
import { mapDriversList } from './mapper/mapDrivers';
import { featureToggles } from '../configuration/setup/featureToggles';
import { getAccessToken } from '../configuration/tokenHandling/tokenSlice';
import { getLocale } from '../configuration/lang/langSlice';
import { isUserSessionExpired } from '../configuration/login/loginSlice';
import type { RootState } from '../configuration/setup/store';
import {
    GeoBookingStates,
    type Asset,
    type CombinedAssetData,
    type Driver,
    type DrivingTimes,
    type DrivingTimesList,
    type GeoBookingState,
    type LiveState,
    type LiveStateList,
    type TransformedData,
} from './types';

enum Status {
    FETCH_SUCCESS = 'FETCH_SUCCESS',
    FETCH_FAILURE = 'FETCH_FAILURE',
}

type CustomResponse<T> = {
    status: Status;
    data?: T;
    error?: Error;
};

const buildFetchResult = async <T>(status: Status, data: T | null, error: Error | null = null) => {
    return Promise.resolve({
        status,
        data,
        error,
    } as CustomResponse<T>);
};

const fetchThen =
    // biome-ignore lint/suspicious/noExplicitAny: We don't know the type
        <T>(mapFunction: (response: any) => any) =>
        async (uri: string, token: string) => {
            try {
                const response = await fetchData(uri, token);
                const data = mapFunction(response);
                return buildFetchResult<T>(Status.FETCH_SUCCESS, data, null);
            } catch (error) {
                return buildFetchResult<T>(Status.FETCH_FAILURE, [] as T, error as Error);
            }
        };

const fetchAssetLiveState = (token: string, locale?: string) => {
    const assetLiveStateUri = `${config.backend.ASSET_LIVE_STATE_SERVICE}/assets?locale=${locale}`;
    return fetchThen<LiveStateList>(mapAssetLiveStates)(assetLiveStateUri, token);
};

const getOrFetchDrivingTimes = async (
    token: string,
    state: RootState,
    forceFetch = false,
    after?: string
): Promise<CustomResponse<DrivingTimesList>> => {
    if (!forceFetch) {
        const drivingTimes = getDrivingTimes(state) || [];
        if (Array.isArray(drivingTimes) && drivingTimes.length !== 0) {
            return buildFetchResult<DrivingTimesList>(
                Status.FETCH_SUCCESS,
                {
                    items: drivingTimes,
                },
                null
            );
        }
    }

    const drivingTimesUri = `${config.backend.DRIVING_TIMES_SERVICE}/drivers${after ? `?after=${after}` : ''}`;
    const drivingTimesResponse = await fetchThen<DrivingTimesList>(mapDriverTimes)(drivingTimesUri, token);

    if (drivingTimesResponse.data?.after) {
        const moreDrivingTimes = await getOrFetchDrivingTimes(token, state, true, drivingTimesResponse.data.after);
        const items = moreDrivingTimes.data?.items || [];
        return buildFetchResult(
            Status.FETCH_SUCCESS,
            {
                items: [...drivingTimesResponse.data.items, ...items],
            },
            null
        );
    }
    return drivingTimesResponse;
};

const getOrFetchAssets = (token: string, state: RootState, forceFetch = false) => {
    if (!forceFetch) {
        const assets = getAssets(state) || [];
        if (Array.isArray(assets) && assets.length !== 0) {
            return buildFetchResult<Asset[]>(Status.FETCH_SUCCESS, assets, null);
        }
    }
    const assetListUri = `${config.backend.LIVEMONITOR_SERVICE}/states/assets`;
    return fetchThen<Asset[]>(mapAssetList)(assetListUri, token);
};

const getOrFetchDrivers = (token: string, state: RootState, forceFetch = false) => {
    if (!forceFetch) {
        const drivers = getDrivers(state) || [];
        if (Array.isArray(drivers) && drivers.length !== 0) {
            return buildFetchResult<Driver[]>(Status.FETCH_SUCCESS, drivers, null);
        }
    }

    const driverListUri = `${config.backend.LIVEMONITOR_SERVICE}/states/drivers`;
    return fetchThen<Driver[]>(mapDriversList)(driverListUri, token);
};

const removeGroupsFromDrivers = (drivers: Driver[]) =>
    drivers.map(driver => ({
        driverId: driver.driverId,
        driverName: driver.driverName,
        firstName: driver.firstName,
        lastName: driver.lastName,
    }));

export const mapAssetsAndDriversWithStates = (
    assets: Asset[],
    liveStates: LiveState[],
    drivers: Driver[],
    drivingTimes: DrivingTimes[]
): CombinedAssetData[] => {
    const assetStatesDictionary = keyBy('vehicleId', liveStates);
    const driversDictionary = keyBy('driverId', removeGroupsFromDrivers(drivers));
    const drivingTimesDictionary = keyBy('driverId', drivingTimes);

    return assets.map((asset: Asset) => {
        const vehicleId = asset.vehicleId;

        const liveState: LiveState | undefined = assetStatesDictionary[vehicleId];

        const driverData: Driver | undefined = liveState?.driverId ? driversDictionary[liveState.driverId] : undefined;

        const drivingTimesData: DrivingTimes | undefined =
            liveState?.driverId &&
            drivingTimesDictionary[liveState.driverId] &&
            drivingTimesDictionary[liveState.driverId].vehicleId === vehicleId
                ? drivingTimesDictionary[liveState.driverId]
                : undefined;

        const mergedData = {
            ...asset,
            ...liveState,
            ...omit('groupIds', driverData),
            ...drivingTimesData,
        } as CombinedAssetData;
        return mergedData;
    });
};

export const findHasAnyGeoBooked = some((asset: Asset) => asset.geoBookingState === GeoBookingStates.active);
export const findHasAnyGeoPending = some((asset: Asset) => asset.geoBookingState === GeoBookingStates.pending);
export const getOverallGeoBookingState = (transformedData: TransformedData[]): GeoBookingState => {
    if (findHasAnyGeoBooked(transformedData)) {
        return GeoBookingStates.active;
    }
    if (findHasAnyGeoPending(transformedData)) {
        return GeoBookingStates.pending;
    }
    return GeoBookingStates.inactive;
};

let callCounterAdministration = 0;
let callCounterDrivingTimes = 0;

export const fetchAssetsAndDriversWithStates =
    (intl: IntlShape, forceFetch?: boolean) => async (dispatch: Dispatch, getState: () => RootState) => {
        const state = getState();
        const token = getAccessToken(state);
        const locale = getLocale(state);

        if (isUserSessionExpired(state)) {
            console.warn('Session expired');
            return;
        }

        const forceFetchAdministration = callCounterAdministration === 0 || !!forceFetch;
        const forceFetchDrivingTimes = callCounterDrivingTimes === 0;

        try {
            const response: {
                0: CustomResponse<LiveStateList>;
                1: CustomResponse<DrivingTimesList>;
                2: CustomResponse<Asset[]>;
                3: CustomResponse<Driver[]>;
            } = await Promise.all([
                fetchAssetLiveState(token, locale),
                getOrFetchDrivingTimes(token, state, forceFetchDrivingTimes),
                getOrFetchAssets(token, state, forceFetchAdministration),
                getOrFetchDrivers(token, state, forceFetchAdministration),
            ]);

            if (response[2].status !== Status.FETCH_SUCCESS) {
                throw response[2].error;
            }

            const liveStates = response[0].data?.items || [];
            const drivingTimes = response[1].data?.items || [];
            const assets = response[2].data || [];
            const drivers = response[3].data || [];

            const mappedAssetsAndDriversWithStates: CombinedAssetData[] = mapAssetsAndDriversWithStates(
                assets,
                liveStates,
                drivers,
                drivingTimes
            );

            dispatch(actions.assetsChanged(assets));
            dispatch(actions.driversChanged(drivers));
            dispatch(actions.drivingTimesChanged(drivingTimes));

            dispatch(actions.rawDataChanged(mappedAssetsAndDriversWithStates));

            const transformedData = transformData(mappedAssetsAndDriversWithStates, intl);
            const overallGeoBookingState = getOverallGeoBookingState(transformedData);

            dispatch(actions.transformedDataChanged(transformedData));
            dispatch(actions.overallGeoBookingStateChanged(featureToggles.simulateGeoBooked || overallGeoBookingState));

            // TODO Decide how often to updates assets and drivers, now 15*20s /60s = every 5 minutes
            callCounterAdministration = (callCounterAdministration + 1) % 15;
            callCounterDrivingTimes = (callCounterDrivingTimes + 1) % 3;
        } catch (error: unknown) {
            if (!error) {
                return;
            }

            const err = error as Error;

            if (err.message.startsWith('401')) {
                // For now silently log unauthenticated errors to the console as the SessionExpiredDialog will
                // be shown instead. No need to render a dedicated error message.
                console.info('Could not fetch data. Please log in.');
            } else {
                dispatch(actions.fetchInitialDataFailed(err));
            }
        }
    };
