import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

export const ClusterSidebarFooter = ({ onClose = () => {} }) => (
    <div>
        <button className={'btn btn-default'} type={'button'} onClick={onClose}>
            <FormattedMessage id={'intl-msg:livemonitor.close'} />
        </button>
    </div>
);

ClusterSidebarFooter.propTypes = {
    onClose: PropTypes.func,
};
