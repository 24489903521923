import { useAppSelector } from '../../configuration/setup/hooks';
import get from 'lodash/fp/get';

const BASE = 'app';

export const isServiceInfoDialogShown = get(`${BASE}.serviceInfo.showServiceInfoDialog`);
export const getServiceInfoDialogChapter = get(`${BASE}.serviceInfo.serviceInfoChapter`);

export const useIsServiceInfoDialogShown = () => useAppSelector(isServiceInfoDialogShown);
export const useServiceInfoDialogChapter = () => useAppSelector(getServiceInfoDialogChapter);
