import type { ThunkDispatch } from 'redux-thunk';
import type { AnyAction } from 'redux';
import isEmpty from 'lodash/fp/isEmpty';
import size from 'lodash/fp/size';
import compact from 'lodash/fp/compact';

import { getActiveAsset, getSelectedAssetGroupIds, getSelectedAssetIds } from '../app/selectors';
import { getFilteredMapAssetData } from './mapSelectors';
import { zoomChanged, centerChanged, boundingBoxChanged, type BoundingBox } from './mapSlice';
import { remoteActions } from '../widgets/remoteActions';
import { sendMessage } from '../widgets/MessageHandler';
import { batch } from 'react-redux';
import type { RootState } from '../../configuration/setup/store';
import type { CoordinatesShort, TransformedData } from '../../services/types';

const BBOX_OFFSET = 0.1;
const SINGLE_ASSET_ZOOM = 18;

export const getBoundingBox = (lats: number[], lngs: number[]) =>
    <BoundingBox>{
        topLeft: { lat: Math.max(...lats), lng: Math.min(...lngs) },
        bottomRight: { lat: Math.min(...lats), lng: Math.max(...lngs) },
    };

const getLatitudePadding = ({ topLeft, bottomRight }: BoundingBox) => (topLeft.lat - bottomRight.lat) * BBOX_OFFSET;
const getLongitudePadding = ({ topLeft, bottomRight }: BoundingBox) => (bottomRight.lng - topLeft.lng) * BBOX_OFFSET;

const getAssetsLats = (assets: TransformedData[]): number[] => compact(assets.map(asset => asset.latitude));
const getAssetsLngs = (assets: TransformedData[]): number[] => compact(assets.map(asset => asset.longitude));

const setBoundingBoxForAssets = (
    assetsToShow: TransformedData[],
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>
) => {
    const lats = getAssetsLats(assetsToShow);
    const lngs = getAssetsLngs(assetsToShow);

    if (isEmpty(lats) || isEmpty(lngs)) {
        return;
    }

    // If there is only one single asset, use zoom and center instead of bounding box
    // as it would really zoom too close to the marker
    if (assetsToShow.length === 1) {
        const asset = assetsToShow[0];
        const center =
            asset?.latitude && asset.longitude
                ? ({ lat: asset.latitude, lng: asset.longitude } as CoordinatesShort)
                : undefined;

        batch(() => {
            dispatch(zoomChanged(SINGLE_ASSET_ZOOM));
            center && dispatch(centerChanged(center));
        });
        return;
    }

    const boundingBox = getBoundingBox(lats, lngs);

    const latitudePadding = getLatitudePadding(boundingBox);
    boundingBox.topLeft.lat += latitudePadding;
    boundingBox.bottomRight.lat -= latitudePadding;

    const longitudePadding = getLongitudePadding(boundingBox);
    boundingBox.topLeft.lng -= longitudePadding;
    boundingBox.bottomRight.lng += longitudePadding;

    batch(() => {
        dispatch(zoomChanged(undefined));
        dispatch(centerChanged(undefined));
        dispatch(boundingBoxChanged(boundingBox));
    });
};

export const showSelectedAssets = (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
    getState: () => RootState
) => {
    const selectedAssetGroupIds = getSelectedAssetGroupIds(getState());
    const selectedAssetIds = getSelectedAssetIds(getState());

    const noAssetSelected = isEmpty(selectedAssetIds);
    const noGroupSelected = isEmpty(selectedAssetGroupIds);
    const singleAssetSelected = size(selectedAssetIds) === 1;

    if ((noAssetSelected || singleAssetSelected) && noGroupSelected) {
        return;
    }

    // Unite both filtered asset lists as the user may have selected a group and asset
    const assetsToShow = getFilteredMapAssetData(getState());

    setBoundingBoxForAssets(assetsToShow, dispatch);
};

export const showAllAssets = (dispatch: ThunkDispatch<RootState, unknown, AnyAction>, getState: () => RootState) => {
    const assetsToShow = getFilteredMapAssetData(getState());
    setBoundingBoxForAssets(assetsToShow, dispatch);
};

export const showActiveAsset = (dispatch: ThunkDispatch<RootState, unknown, AnyAction>, getState: () => RootState) => {
    const assetToShow = getActiveAsset(getState());
    if (assetToShow) {
        setBoundingBoxForAssets([assetToShow], dispatch);
    }
};

export const remoteZoomToMapElements = () => {
    sendMessage(remoteActions.zoomToMapElements());
};
