import { useEffect } from 'react';
import { useLocation, useNavigationType, type Location } from 'react-router-dom';

import { useAppDispatch } from '../configuration/setup/hooks';
import { usePreviousLocation } from './usePreviousLocation';
import { actions } from '../services/actions';

type NavigationActionType = 'PUSH' | 'POP' | 'REPLACE';

export const useLocationChange = () => {
    const location = useLocation();
    const navigationAction = useNavigationType();
    const prevLocation = usePreviousLocation(location);
    const dispatch = useAppDispatch();

    // After mount, restore state from URL once
    useEffect(() => {
        dispatch(
            actions.routeChanged({
                action: 'PUSH',
                isFirstRendering: true,
                location,
            })
        );
    }, []);

    // execute on location change
    useEffect(() => handleLocationChange(location, navigationAction), [location, navigationAction]);

    const handleLocationChange = (location: Location, action: NavigationActionType) => {
        if (action === 'PUSH') {
            // URL change initiated by the application, so do nothing to avoid endless update loop
            return;
        }

        if (action === 'REPLACE') {
            // URL change initiated by your application using replace i.e. when switching main views (list/map)
            return;
        }

        if (action === 'POP') {
            // URL change initiated by user (back/forward/address-bar)
            dispatch(
                actions.routeChanged({
                    action,
                    isFirstRendering: !prevLocation,
                    location,
                })
            );
        }
    };
};
