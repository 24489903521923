import { handleActions } from 'redux-actions';

import { serviceInfoActions } from './serviceInfoActions';
import { chapters } from './chapters';

export const defaultServiceInfoState = {
    showServiceInfoDialog: false,
    serviceInfoChapter: chapters.welcome,
};

export const serviceInfoReducer = handleActions(
    {
        [serviceInfoActions.serviceInfoDialogClosed]: state => {
            return {
                ...state,
                showServiceInfoDialog: false,
            };
        },
        [serviceInfoActions.serviceInfoDialogOpenedAtChapter]: (state, action) => {
            return {
                showServiceInfoDialog: true,
                serviceInfoChapter: action.payload,
            };
        },
    },
    defaultServiceInfoState
);
