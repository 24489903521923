import useEffectOnce from '@rio-cloud/rio-uikit/useEffectOnce';
import { useState } from 'react';

export const useHideOnSmallScreen = (): boolean => {
    const [isVisible, setIsVisible] = useState(false);

    useEffectOnce(() => {
        const handleVisibility = () => {
            if (typeof window !== 'undefined') {
                setIsVisible(window.innerHeight > 500);
            }
        };

        window.addEventListener('load', handleVisibility);
        window.addEventListener('resize', handleVisibility);

        handleVisibility();

        return () => {
            window.removeEventListener('load', handleVisibility);
        };
    });

    return isVisible;
};
